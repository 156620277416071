import logo from './logo.svg';
import{ Routes, Route } from 'react-router-dom'
import './App.css';
import Home from './components/home/Home';
import Formhati from './components/Fhati/Formhati';
import Result from './components/Fhati/Result';
import FormMineral from './components/mineral/FormMineral';
import MineralResult from './components/mineral/MineralResult';
import FormGdd from './components/gdd/FormGdd';
import GddResult from './components/gdd/GddResult';
import FormRTulang from './components/rt/FormRTulang';
import RTResult from './components/rt/RTResult';
import FormSarafOtak from './components/sf/FormSarafOtak';
import SarafOResult from './components/sf/SarafOResult';
import FormFungsiP from './components/fParu/FormFungsiP';
import FungsiPResult from './components/fParu/FungsiPResult';
import FormFGinjal from './components/FGinjal/FormFGinjal';
import FungsiGinjalResult from './components/FGinjal/FungsiGinjalResult';
import FormSaluranP from './components/fsp/FormSaluranP';
import SaluranPResult from './components/fsp/SaluranPResult';
import FormJantungO from './components/pdjo/FormJantungO';
import JOResult from './components/pdjo/JOResult';
import ListAllData from './components/allData/ListAllData';
import AllHati from './components/Fhati/AllHati';
import AllMineral from './components/mineral/AllMineral';
import AllGdarah from './components/gdd/AllGdarah';
import AllRtulang from './components/rt/AllRtulang';
import AllSarafOtak from './components/sf/AllSarafOtak';
import AllParu from './components/fParu/AllParu';
import AllFGinjal from './components/FGinjal/AllFGinjal';
import AllSaluranP from './components/fsp/AllSaluranP';
import AllJantungO from './components/pdjo/AllJantungO';


function App() {
  return (
    <div className="App">
      <Routes>
        <Route path='/' element={<Home />}/>

        <Route path='/hati' element={<Formhati />} />
        <Route path='/resulth/:name' element={<Result />} />
        <Route path='/listall/allhati' element={<AllHati />} />
        
        <Route path='/mineral' element={<FormMineral />} />
        <Route path='/mr/:name' element={<MineralResult />} /> 
        <Route path='/listall/allm' element={<AllMineral />} /> 

        <Route path='/gdd' element={<FormGdd />} />
        <Route path='/gddr/:name' element={<GddResult />} />
        <Route path='/listall/gdarah' element={<AllGdarah />} />

        <Route path='/rt' element={<FormRTulang />} />
        <Route path='/rtr/:name' element={<RTResult />} />
        <Route path='/listall/rta' element={<AllRtulang />} />

        <Route path='/so' element={<FormSarafOtak />} />
        <Route path='/sor/:name' element={<SarafOResult />} />
        <Route path='/listall/sro' element={<AllSarafOtak />} />

        <Route path='/fp' element={<FormFungsiP />} />
        <Route path='/fpr/:name' element={<FungsiPResult />} />
        <Route path='/listall/afp' element={<AllParu />} />

        <Route path='/fg' element={<FormFGinjal />} />
        <Route path='/fgr/:name' element={<FungsiGinjalResult />} />
        <Route path='/listall/afg' element={<AllFGinjal />} />

        <Route path='/fsp' element={<FormSaluranP />} />
        <Route path='/fspr/:name' element={<SaluranPResult />} />
        <Route path='/listall/afsp' element={<AllSaluranP />} />

        <Route path='/pdjo' element={<FormJantungO />} />
        <Route path='/pdjor/:name' element={<JOResult />} />
        <Route path='/listall/apdjor' element={<AllJantungO />} />

        <Route path='listall' element={<ListAllData />} />
      </Routes>
    </div>
  );
}

export default App;
