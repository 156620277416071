import React from 'react'
import { Link } from 'react-router-dom'
import galeh from '../../assets/galeh.png'
import hati from '../../assets/hati.png'
import mineral from '../../assets/mineral.png'
import dua from '../../assets/2.png'
import tiga from '../../assets/3.png'
import empat from '../../assets/4.png'
import lima from '../../assets/5.png'
import enam from '../../assets/6.png'
import tujuh from '../../assets/7.png'
import delapan from '../../assets/8.png'

const Home = () => {
  return (
    <div>
      <div className='sectionHero pt-24' id='hero'>
        <div className='container '>
          <div className='flex flex-wrap justify-between'>
            <div className='w-full self-center px-24 lg:w-1/3'>
              <h1 className='font-semibold text-primary text-4xl'>Sehat BosQ</h1>
              <h2 className='font-medium text-slate-500 text-lg mb-5'>Sehat Selalu BosQ</h2>
              <p className='font-medium text-slate-400 text-lg mb-10'>Kesehatan adalah prioritas utama yang harus di jaga, karena kesehatan akan berdampak kepada aktfitas sehari-hari</p>

              <Link to='listall'> <button className='text-base font-semibold text-white bg-primary py-3 px-8 rounded-full hover:shadow-lg hover:opacity-80 transition duration-300 ease-in-out'>Lihat semua data</button></Link>
            </div>
            <div>
              <div className='container w-full self-end px-4 lg:w-1/2'>
                <div className='mt-10 ml-32 relative'>
                  <img src={galeh} width="600" alt='Galeh' className='max-w-ful ' />
                  <span className='absolute -bottom-32 -z-10 left-1/2 -translate-x-1/2'>
                  <svg width="300" height="370" viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
                    <path fill="#14b8a6" d="M52.8,-55C65.1,-40.5,69.3,-20.3,69.2,-0.1C69.1,20,64.5,40,52.3,55C40,69.9,20,79.9,-0.7,80.6C-21.3,81.2,-42.6,72.6,-58.9,57.6C-75.1,42.6,-86.4,21.3,-83.8,2.6C-81.2,-16.2,-64.9,-32.4,-48.6,-46.8C-32.4,-61.2,-16.2,-73.8,2,-75.9C20.3,-77.9,40.5,-69.4,52.8,-55Z" transform="translate(100 100) "/>
                  </svg>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='sectionFeatures pt-36 pb-32 mt-32' id='features'>
        <h1 className='text-center font-bold text-4xl'>Tools or Features</h1>
        
        <div className='container max-w-full mx-auto flex mt-12 justify-center text-center'>
          <div className='mx-5'>
            <div className='rounded shadow-sm border'>
              <img src={hati} width='400'/>
              <h3 className='text-xl text-dark font-bold'>Fungsi Hati</h3>
              <Link to='hati' className=''><button className='bg-primary p-3 text-white font-semibold rounded mt-2 mb-3 hover:shadow-lg hover:opacity-80 transition duration-300 ease-in-out'>Let try</button></Link>
            </div>
          </div>

          <div className='mx-5'>
          <div className='rounded shadow-sm border'>
              <img src={mineral} width='400' />
              <h3 className='text-xl text-dark font-bold'>Kandungan Mineral</h3>
              <Link to='mineral' className=''><button className='bg-primary p-3 text-white font-semibold rounded mt-2 mb-3 hover:shadow-lg hover:opacity-80 transition duration-300 ease-in-out'>Let try</button></Link>
            </div>
          </div>

          <div className='mx-5'>
          <div className='rounded shadow-sm border'>
              <img src={dua} width='400' />
              <h3 className='text-xl text-dark font-bold'>Gula Dalam Darah</h3>
              <Link to='gdd' className=''><button className='bg-primary p-3 text-white font-semibold rounded mt-2 mb-3 hover:shadow-lg hover:opacity-80 transition duration-300 ease-in-out'>Let try</button></Link>
            </div>
          </div>
        </div>

        <div className='container max-w-full mx-auto flex mt-12 justify-center text-center'>
          <div className='mx-5'>
            <div className='rounded shadow-sm border'>
              <img src={tiga} width='400'/>
              <h3 className='text-xl text-dark font-bold'>Penyakit Rematik Tulang</h3>
              <Link to='rt' className=''><button className='bg-primary p-3 text-white font-semibold rounded mt-2 mb-3 hover:shadow-lg hover:opacity-80 transition duration-300 ease-in-out'>Let try</button></Link>
            </div>
          </div>

          <div className='mx-5'>
          <div className='rounded shadow-sm border'>
              <img src={empat} width='400' />
              <h3 className='text-xl text-dark font-bold'>Saraf Otak</h3>
              <Link to='so' className=''><button className='bg-primary p-3 text-white font-semibold rounded mt-2 mb-3 hover:shadow-lg hover:opacity-80 transition duration-300 ease-in-out'>Let try</button></Link>
            </div>
          </div>

          <div className='mx-5'>
          <div className='rounded shadow-sm border'>
              <img src={lima} width='400' />
              <h3 className='text-xl text-dark font-bold'>Fungsi Paru</h3>
              <Link to='fp' className=''><button className='bg-primary p-3 text-white font-semibold rounded mt-2 mb-3 hover:shadow-lg hover:opacity-80 transition duration-300 ease-in-out'>Let try</button></Link>
            </div>
          </div>

        </div>

        <div className='container max-w-full mx-auto flex mt-12 justify-center text-center'>
          <div className='mx-5'>
            <div className='rounded shadow-sm border'>
              <img src={enam} width='400'/>
              <h3 className='text-xl text-dark font-bold'>Fungsi Ginjal</h3>
              <Link to='fg' className=''><button className='bg-primary p-3 text-white font-semibold rounded mt-2 mb-3 hover:shadow-lg hover:opacity-80 transition duration-300 ease-in-out'>Let try</button></Link>
            </div>
          </div>

          <div className='mx-5'>
          <div className='rounded shadow-sm border'>
              <img src={tujuh} width='400' />
              <h3 className='text-xl text-dark font-bold'>Fungsi Saluran Pencernaan</h3>
              <Link to='fsp' className=''><button className='bg-primary p-3 text-white font-semibold rounded mt-2 mb-3 hover:shadow-lg hover:opacity-80 transition duration-300 ease-in-out'>Let try</button></Link>
            </div>
          </div>

          <div className='mx-5'>
          <div className='rounded shadow-sm border'>
              <img src={delapan} width='400' />
              <h3 className='text-xl text-dark font-bold'>Peredaran darah di jantung dan di Otak</h3>
              <Link to='pdjo' className=''><button className='bg-primary p-3 text-white font-semibold rounded mt-2 mb-3 hover:shadow-lg hover:opacity-80 transition duration-300 ease-in-out'>Let try</button></Link>
            </div>
          </div>

        </div>
      </div>
    </div>
  )
}

export default Home
