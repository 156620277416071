import React from 'react'
import { Link, json, useParams} from 'react-router-dom';
import { useState, useEffect, useRef } from 'react';
import { useReactToPrint } from 'react-to-print';

const SarafOResult = () => {

    const { name } = useParams();
    const [data, setData] = useState(null);
    const componentRef = useRef();
    const comparation = {
        pdjo: {
            min: 143.37,
            max: 210.81
        },
        apdo: {
            min: 0.203,
            max: 0.642
        },
        fso: {
            min: 0.253,
            max: 0.659
        },
        ts: {
            min: 0.109,
            max: 0.351
        },
        im: {
            min: 0.442,
            max: 0.817
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
              const headers = {
                'Content-type': 'application/json;',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Methods': 'GET, POST'
              };
      
            //   const response = await fetch(`http://localhost:8080/api/so/${name}`, { headers });
              const response = await fetch(`http://103.106.72.182:36013/api/so/${name}`, { headers });
              const jsonData = await response.json();
              setData(jsonData);
            } catch (error) {
              console.log(error);
            }
        }

        fetchData();
        
    }, [name])

    const printHandler = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: 'Data Sehat',
        onAfterPrint: () => alert('Print success'),
    });

    let resultDate
    if(data != null) {
        const dataString = data.data.createdAt
        const date = new Date(dataString)
        resultDate = date.toLocaleDateString('id-ID', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
        });
    }

    const comparationResult = (tipe, value) => {
        if(tipe === 'pdjo') {
            if(value < comparation.pdjo.max && value > comparation.pdjo.min) {
                return "normal"
            } else if(value > comparation.pdjo.max) {
                return 'more'
            } else if(value < comparation.pdjo.min) {
                return 'low'
            }
        } else if(tipe === 'apdo') {
            if(value < comparation.apdo.max && value > comparation.apdo.min) {
                return "normal"
            } else if(value > comparation.apdo.max) {
                return 'more'
            } else if(value < comparation.apdo.min) {
                return 'low'
            }
        } else if(tipe === 'fso') {
            if(value < comparation.fso.max && value > comparation.fso.min) {
                return "normal"
            } else if(value > comparation.fso.max) {
                return 'more'
            } else if(value < comparation.fso.min) {
                return 'low'
            }
        } else if(tipe === 'ts') {
            if(value < comparation.ts.max && value > comparation.ts.min) {
                return "normal"
            } else if(value > comparation.ts.max) {
                return 'more'
            } else if(value < comparation.ts.min) {
                return 'low'
            }
        } else if(tipe === 'im') {
            if(value < comparation.im.max && value > comparation.im.min) {
                return "normal"
            } else if(value > comparation.im.max) {
                return 'more'
            } else if(value < comparation.im.min) {
                return 'low'
            }
        }
    }

  return (
    <div  ref={componentRef} className='mb-7'>
      {data && (
        <div className='w-10/12 mx-auto bg-white shadow-md p-2 mt-2 '>
            <div className='w-8/12 bg-gray-100 mx-auto mt-8 p-2 pt-2 rounded'>
                <div className='flex space-x-32'>
                    <div>
                        <h5 className='m-3 font-semibold'>Nama: {data.data.name}</h5>
                        <h5 className='m-3 font-semibold'>Umur: {data.data.age}</h5>
                    </div>
                    <div>
                        <h5 className='m-3 font-semibold'>Gender: {data.data.gender}</h5>
                        <h5 className='m-3 font-semibold'>Tanggal: {resultDate}</h5>
                    </div>
                </div>
            </div>
            <div>
                
            <div className="relative overflow-x-auto shadow-md sm:rounded-lg mt-8">
                <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                        <tr>
                            <th scope="col" className="px-6 py-3">
                                Barang pengujian
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Rentang Normal
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Nilai Pengukuran yang sebenarnya
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Hasil Pengujian
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr className="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
                            <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                Pasokan Darah ke Jaringan Otak
                            </th>
                            <td className="px-6 py-4 text-center">
                                143.37 - 210.81
                            </td>
                            <td className="px-6 py-4 text-center">
                                {data.data.pdjo}
                            </td>
                            <td className="px-6 py-4">
                                <div className={`${comparationResult('pdjo', data.data.pdjo) == 'normal' ? 'bg-primary' : comparationResult('pdjo', data.data.pdjo) == 'more' ? 'bg-yellow-400' : comparationResult('pdjo', data.data.pdjo) == 'low' ? 'bg-red-600' : ''} w-20 h-8 rounded-sm`}></div>
                            </td>
                        </tr>
                        <tr className="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
                            <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                Arteriosklerosis Pembulu Darah Otak
                            </th>
                            <td className="px-6 py-4 text-center">
                                0.203 - 0.642
                            </td>
                            <td className="px-6 py-4 text-center">
                                {data.data.apdo}
                            </td>
                            <td className="px-6 py-4">
                            <div className={`${comparationResult('apdo', data.data.apdo) == 'normal' ? 'bg-primary' : comparationResult('apdo', data.data.apdo) == 'more' ? 'bg-yellow-400' : comparationResult('apdo', data.data.apdo) == 'low' ? 'bg-red-600' : ''} w-20 h-8 rounded-sm`}></div>
                            </td>
                        </tr>
                        <tr className="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
                            <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                Fungsi Saraf Otak
                            </th>
                            <td className="px-6 py-4 text-center">
                                0.253 - 0.659
                            </td>
                            <td className="px-6 py-4 text-center">
                                {data.data.fso}
                            </td>
                            <td className="px-6 py-4">
                            <div className={`${comparationResult('fso', data.data.fso) == 'normal' ? 'bg-primary' : comparationResult('fso', data.data.fso) == 'more' ? 'bg-yellow-400' : comparationResult('fso', data.data.fso) == 'low' ? 'bg-red-600' : ''} w-20 h-8 rounded-sm`}></div>
                            </td>
                        </tr>
                        <tr className="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
                            <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                Tingkat Sentimen
                            </th>
                            <td className="px-6 py-4 text-center">
                                0.109 - 0.351
                            </td>
                            <td className="px-6 py-4 text-center">
                                {data.data.ts}
                            </td>
                            <td className="px-6 py-4">
                            <div className={`${comparationResult('ts', data.data.ts) == 'normal' ? 'bg-primary' : comparationResult('ts', data.data.ts) == 'more' ? 'bg-yellow-400' : comparationResult('ts', data.data.ts) == 'low' ? 'bg-red-600' : ''} w-20 h-8 rounded-sm`}></div>
                            </td>
                        </tr>
                        <tr className="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
                            <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                Index Memori (ZS)
                            </th>
                            <td className="px-6 py-4 text-center">
                                0.442 - 0.817
                            </td>
                            <td className="px-6 py-4 text-center">
                                {data.data.im}
                            </td>
                            <td className="px-6 py-4">
                            <div className={`${comparationResult('im', data.data.im) == 'normal' ? 'bg-primary' : comparationResult('im', data.data.im) == 'more' ? 'bg-yellow-400' : comparationResult('im', data.data.im) == 'low' ? 'bg-red-600' : ''} w-20 h-8 rounded-sm`}></div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div className='mt-4 text-right float-right flex gap-8'>
                <div>
                    <p className='font-semibold'>Kurang</p>
                    <div  className='w-12 h-4 bg-red-600 rounded'></div>
                </div>
                <div>
                    <p className='font-semibold'>Normal</p>
                    <div  className='w-12 h-4 bg-primary rounded'></div>
                </div>
                <div>
                    <p className='font-semibold'>Abnormal</p>
                    <div  className='w-12 h-4 bg-yellow-400 rounded'></div>
                </div>

                <div></div>
            </div>

            </div>
            <button className='bg-primary hover:bg-teal-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mx-2 my-8' onClick={printHandler}>Print</button>
            <Link to='/so' ><button className='bg-yellow-400 hover:bg-yellow-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mx-1 mt-3'>Kembali</button></Link>
        </div>
      )}
    </div>
  )
}

export default SarafOResult
