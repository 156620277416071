import React from 'react'
import { Link, json, useParams} from 'react-router-dom';
import { useState, useEffect, useRef } from 'react';
import { useReactToPrint } from 'react-to-print';


const JOResult = () => {

    const { name } = useParams();
    const [data, setData] = useState(null);
    const componentRef = useRef();
    const comparation = {
        kd: {
            min: 48.264,
            max: 65.371
        },
        kpk: {
            min: 56.749,
            max: 67.522
        },
        ld: {
            min: 0.841,
            max: 1.043
        },
        hpd: {
            min: 0.327,
            max: 0.937
        },
        kbd: {
            min: 1.672,
            max: 1.978
        },
        kdoj: {
            min: 0.192,
            max: 0.412
        },
        vdoj: {
            min: 4.832,
            max: 5.147
        }

    }

    useEffect(() => {
        const fetchData = async () => {
            try {
              const headers = {
                'Content-type': 'application/json;',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Methods': 'GET, POST'
              };
      
            //   const response = await fetch(`http://localhost:8080/api/djo/${name}`, { headers });
              const response = await fetch(`http://103.106.72.182:36013/api/djo/${name}`, { headers });
              const jsonData = await response.json();
              setData(jsonData);
            } catch (error) {
              console.log(error);
            }
        }

        fetchData();
        
    }, [name])

    const printHandler = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: 'Data Sehat',
        onAfterPrint: () => alert('Print success'),
    });

    let resultDate
    if(data != null) {
        const dataString = data.data.createdAt
        const date = new Date(dataString)
        resultDate = date.toLocaleDateString('id-ID', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
        });
    }

    const comparationResult = (tipe, value) => {
        if(tipe === 'kd') {
            if(value < comparation.kd.max && value > comparation.kd.min) {
                return "normal"
            } else if(value > comparation.kd.max) {
                return 'more'
            } else if(value < comparation.kd.min) {
                return 'low'
            }
        } else if(tipe === 'kpk') {
            if(value < comparation.kpk.max && value > comparation.kpk.min) {
                return "normal"
            } else if(value > comparation.kpk.max) {
                return 'more'
            } else if(value < comparation.kpk.min) {
                return 'low'
            }
        } else if(tipe === 'ld') {
            if(value < comparation.ld.max && value > comparation.ld.min) {
                return "normal"
            } else if(value > comparation.ld.max) {
                return 'more'
            } else if(value < comparation.ld.min) {
                return 'low'
            }
        } else if(tipe === 'hpd') {
            if(value < comparation.hpd.max && value > comparation.hpd.min) {
                return "normal"
            } else if(value > comparation.hpd.max) {
                return 'more'
            } else if(value < comparation.hpd.min) {
                return 'low'
            }
        } else if(tipe === 'kbd') {
            if(value < comparation.kbd.max && value > comparation.kbd.min) {
                return "normal"
            } else if(value > comparation.kbd.max) {
                return 'more'
            } else if(value < comparation.kbd.min) {
                return 'low'
            }
        } else if(tipe === 'kdoj') {
            if(value < comparation.kdoj.max && value > comparation.kdoj.min) {
                return "normal"
            } else if(value > comparation.kdoj.max) {
                return 'more'
            } else if(value < comparation.kdoj.min) {
                return 'low'
            }
        } else if(tipe === 'vdoj') {
            if(value < comparation.vdoj.max && value > comparation.vdoj.min) {
                return "normal"
            } else if(value > comparation.vdoj.max) {
                return 'more'
            } else if(value < comparation.vdoj.min) {
                return 'low'
            }
        }
    }

  return (
    <div  ref={componentRef} className='mb-7'>
      {data && (
        <div className='w-10/12 mx-auto bg-white shadow-md p-2 mt-2 '>
            <div className='w-8/12 bg-gray-100 mx-auto mt-8 p-2 pt-2 rounded'>
                <div className='flex space-x-32'>
                    <div>
                        <h5 className='m-3 font-semibold'>Nama: {data.data.name}</h5>
                        <h5 className='m-3 font-semibold'>Umur: {data.data.age}</h5>
                    </div>
                    <div>
                        <h5 className='m-3 font-semibold'>Gender: {data.data.gender}</h5>
                        <h5 className='m-3 font-semibold'>Tanggal: {resultDate}</h5>
                    </div>
                </div>
            </div>
            <div>
                
            <div className="relative overflow-x-auto shadow-md sm:rounded-lg mt-8">
                <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                        <tr>
                            <th scope="col" className="px-6 py-3">
                                Barang pengujian
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Rentang Normal
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Nilai Pengukuran yang sebenarnya
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Hasil Pengujian
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr className="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
                            <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                Kekentalan Darah
                            </th>
                            <td className="px-6 py-4 text-center">
                                48.264 - 65.371
                            </td>
                            <td className="px-6 py-4 text-center">
                                {data.data.kd}
                            </td>
                            <td className="px-6 py-4">
                                <div className={`${comparationResult('kd', data.data.kd) == 'normal' ? 'bg-primary' : comparationResult('kd', data.data.kd) == 'more' ? 'bg-yellow-400' : comparationResult('kd', data.data.kd) == 'low' ? 'bg-red-600' : ''} w-20 h-8 rounded-sm`}></div>
                            </td>
                        </tr>
                        <tr className="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
                            <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                Kristal atau Plak Kolestrol
                            </th>
                            <td className="px-6 py-4 text-center">
                                56.749 - 67.522
                            </td>
                            <td className="px-6 py-4 text-center">
                                {data.data.kpk}
                            </td>
                            <td className="px-6 py-4">
                            <div className={`${comparationResult('kpk', data.data.kpk) == 'normal' ? 'bg-primary' : comparationResult('kpk', data.data.kpk) == 'more' ? 'bg-yellow-400' : comparationResult('kpk', data.data.kpk) == 'low' ? 'bg-red-600' : ''} w-20 h-8 rounded-sm`}></div>
                            </td>
                        </tr>
                        <tr className="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
                            <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                Lemak Darah
                            </th>
                            <td className="px-6 py-4 text-center">
                                0.841 - 1.043
                            </td>
                            <td className="px-6 py-4 text-center">
                                {data.data.ld}
                            </td>
                            <td className="px-6 py-4">
                            <div className={`${comparationResult('ld', data.data.ld) == 'normal' ? 'bg-primary' : comparationResult('ld', data.data.ld) == 'more' ? 'bg-yellow-400' : comparationResult('ld', data.data.ld) == 'low' ? 'bg-red-600' : ''} w-20 h-8 rounded-sm`}></div>
                            </td>
                        </tr>
                        <tr className="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
                            <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                Hambatan Pembulu Darah
                            </th>
                            <td className="px-6 py-4 text-center">
                                0.327 - 0.937
                            </td>
                            <td className="px-6 py-4 text-center">
                                {data.data.hpd}
                            </td>
                            <td className="px-6 py-4">
                            <div className={`${comparationResult('hpd', data.data.hpd) == 'normal' ? 'bg-primary' : comparationResult('hpd', data.data.hpd) == 'more' ? 'bg-yellow-400' : comparationResult('hpd', data.data.hpd) == 'low' ? 'bg-red-600' : ''} w-20 h-8 rounded-sm`}></div>
                            </td>
                        </tr>
                        <tr className="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
                            <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                Kelenturan Pembulu Darah
                            </th>
                            <td className="px-6 py-4 text-center">
                                1.672 - 1.978
                            </td>
                            <td className="px-6 py-4 text-center">
                                {data.data.kbd}
                            </td>
                            <td className="px-6 py-4">
                            <div className={`${comparationResult('kbd', data.data.kbd) == 'normal' ? 'bg-primary' : comparationResult('kbd', data.data.kbd) == 'more' ? 'bg-yellow-400' : comparationResult('kbd', data.data.kbd) == 'low' ? 'bg-red-600' : ''} w-20 h-8 rounded-sm`}></div>
                            </td>
                        </tr>
                        <tr className="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
                            <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                Kebutuhan Darah Otot Jantung
                            </th>
                            <td className="px-6 py-4 text-center">
                                0.192 - 0.412
                            </td>
                            <td className="px-6 py-4 text-center">
                                {data.data.kdoj}
                            </td>
                            <td className="px-6 py-4">
                            <div className={`${comparationResult('kdoj', data.data.kdoj) == 'normal' ? 'bg-primary' : comparationResult('kdoj', data.data.kdoj) == 'more' ? 'bg-yellow-400' : comparationResult('kdoj', data.data.kdoj) == 'low' ? 'bg-red-600' : ''} w-20 h-8 rounded-sm`}></div>
                            </td>
                        </tr>
                        <tr className="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
                            <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                Volume Darah yang Diserap Oleh Otot jantung
                            </th>
                            <td className="px-6 py-4 text-center">
                                4.832 - 5.147
                            </td>
                            <td className="px-6 py-4 text-center">
                                {data.data.vdoj}
                            </td>
                            <td className="px-6 py-4">
                            <div className={`${comparationResult('vdoj', data.data.vdoj) == 'normal' ? 'bg-primary' : comparationResult('vdoj', data.data.vdoj) == 'more' ? 'bg-yellow-400' : comparationResult('vdoj', data.data.vdoj) == 'low' ? 'bg-red-600' : ''} w-20 h-8 rounded-sm`}></div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div className='mt-4 text-right float-right flex gap-8'>
                <div>
                    <p className='font-semibold'>Kurang</p>
                    <div  className='w-12 h-4 bg-red-600 rounded'></div>
                </div>
                <div>
                    <p className='font-semibold'>Normal</p>
                    <div  className='w-12 h-4 bg-primary rounded'></div>
                </div>
                <div>
                    <p className='font-semibold'>Abnormal</p>
                    <div  className='w-12 h-4 bg-yellow-400 rounded'></div>
                </div>

                <div></div>
            </div>

            </div>
            <button className='bg-primary hover:bg-teal-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mx-2 my-8' onClick={printHandler}>Print</button>
            <Link to='/pdjo' ><button className='bg-yellow-400 hover:bg-yellow-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mx-1 mt-3'>Kembali</button></Link>
        </div>
      )}
    </div>
  )
}

export default JOResult
