import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Link, useHistory } from 'react-router-dom';

const FormSarafOtak = () => {

    const [name, setName] = useState('');
    const [age, setAge] = useState('');
    const [gender, setGender] = useState('');
    const [pdjo, setPdjo] = useState('');
    const [apdo, setApdo] = useState('');
    const [fso, setFso] = useState('');
    const [ts, setTs] = useState('');
    const [im, setIm] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault()

        const data = {
            "name" : name,
            "age" : parseInt(age),
            "gender" : gender,
            "pdjo" : parseFloat(pdjo),
            "apdo": parseFloat(apdo),
            "fso": parseFloat(fso),
            "ts": parseFloat(ts),
            "im": parseFloat(im)
        }

        // fetch('http://localhost:8080/api/so', {
        fetch('http://103.106.72.182:36013/api/so', {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-type': 'application/json;',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Methods': 'GET, POST'
            }         
        })
        .then(res => console.log(res))
        .catch(err => console.log(err))
    }

  return (
    <div>
      <h1 className='text-center font-bold text-2xl container mx-auto pt-8'>(Saraf Otak) Form Hasil Analisa</h1>
        <div className='bg-slate-100 shadow-md container mx-auto mt-8 p-8 w-1/2 mb-8'>
            <form className='flex' onSubmit={handleSubmit}>
                <div className='border-inherit w-96'>
                    <div className='p-3'>
                        <label htmlFor="name" className='block text-gray-700 font-bold mb-1'>Nama: </label>
                        <input
                         type='text'
                         name='name'
                         value={name}
                         id='name'
                         onChange={(e) => setName(e.target.value)} className='shadow appearance-none border rounded w-64 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'/>
                    </div>
                    <div className='p-3'>
                        <label htmlFor="gender" className="block text-gray-700 font-bold mb-1" >Gender: </label>
                        <select
                            id="gender"
                            name='gender'
                            value={gender}
                            onChange={(e) => setGender(e.target.value)}
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
                            <option value="">Pilih Jenis Kelamin</option>
                            <option value="Laki-laki">Laki-laki</option>
                            <option value="Perempuan">Perempuan</option>
                        </select>
                    </div>
                    <div className='p-3'>
                        <label htmlFor="age" className="block text-gray-700 font-bold mb-1">Umur: </label>
                        <input
                         type='number'
                         name='age'
                         value={age}
                         id='name'
                         min="0"
                         onChange={(e) => setAge(e.target.value)} className='shadow appearance-none border rounded w-64 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'/>
                    </div>

                    
                </div>

                <div>
                    <div className='p-3'>
                        <label htmlFor="pdjo" className="block text-gray-700 font-bold mb-1">Pasokan Darah ke Jaringan Otak: </label>
                            <input
                            type='text'
                            name='pdjo'
                            value={pdjo}
                            id='pdjo'
                            onChange={(e) => setPdjo(e.target.value)} className='shadow appearance-none border rounded w-64 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'/>
                    </div>
                    <div className='p-3'>
                        <label htmlFor="apdo" className="block text-gray-700 font-bold mb-1">Arteriosklerosis Pembulu Darah Otak: </label>
                                <input
                                type='text'
                                name='apdo'
                                value={apdo}
                                id='apdo'
                                onChange={(e) => setApdo(e.target.value)} className='shadow appearance-none border rounded w-64 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'/>
                    </div>
                    <div className='p-3'>
                        <label htmlFor="fso" className="block text-gray-700 font-bold mb-1">Fungsi Saraf Otak: </label>
                                <input
                                type='text'
                                name='fso'
                                value={fso}
                                id='fso'
                                onChange={(e) => setFso(e.target.value)} className='shadow appearance-none border rounded w-64 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'/>
                    </div>
                    <div className='p-3'>
                        <label htmlFor="ts" className="block text-gray-700 font-bold mb-1">Tingkat Sentimen: </label>
                                    <input
                                    type='text'
                                    name='ts'
                                    value={ts}
                                    id='ts'
                                    onChange={(e) => setTs(e.target.value)} className='shadow appearance-none border rounded w-64 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'/>
                    </div>
                    <div className='p-3'>
                        <label htmlFor="im" className="block text-gray-700 font-bold mb-1">Index Memori (ZS): </label>
                                    <input
                                    type='text'
                                    name='im'
                                    value={im}
                                    id='im'
                                    onChange={(e) => setIm(e.target.value)} className='shadow appearance-none border rounded w-64 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'/>
                    </div>
                    
                    <button type="submit" className="bg-primary hover:bg-teal-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mx-1 mt-8"> Submit </button>

                    <Link to={`/sor/${name}`} state={name}>
                    <button className='bg-lime-400 hover:bg-lime-500 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline'>Cek Hasil</button>
                    </Link>

                    <Link to='/'>
                    <button className='block mt-8 bg-yellow-400 hover:bg-yellow-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mx-1'>Kembali</button>
                    </Link>

                </div>
                
            </form>
        </div>
    </div>
  )
}

export default FormSarafOtak
