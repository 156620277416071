import React from 'react'
import { Link, json, useParams} from 'react-router-dom';
import { useState, useEffect, useRef } from 'react';
import { useReactToPrint } from 'react-to-print';

const FungsiPResult = () => {

  const { name } = useParams();
    const [data, setData] = useState(null);
    const componentRef = useRef();
    const comparation = {
        vc: {
            min: 3348,
            max: 3529
        },
        tlc: {
            min: 4301,
            max: 4782
        },
        tjn: {
            min: 1.374,
            max: 1.709
        },
        tpdap: {
            min: 17.903,
            max: 21.012
        }
    }

    useEffect(() => {
      const fetchData = async () => {
          try {
            const headers = {
              'Content-type': 'application/json;',
              'Access-Control-Allow-Origin': '*',
              'Access-Control-Allow-Methods': 'GET, POST'
            };
    
            // const response = await fetch(`http://localhost:8080/api/fp/${name}`, { headers });
            const response = await fetch(`http://103.106.72.182:36013/api/fp/${name}`, { headers });
            const jsonData = await response.json();
            setData(jsonData);
          } catch (error) {
            console.log(error);
          }
      }

      fetchData();
      
  }, [name])

  const printHandler = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: 'Data Sehat',
    onAfterPrint: () => alert('Print success'),
});

let resultDate
if(data != null) {
    const dataString = data.data.createdAt
    const date = new Date(dataString)
    resultDate = date.toLocaleDateString('id-ID', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
    });
}

const comparationResult = (tipe, value) => {
  if(tipe === 'vc') {
      if(value < comparation.vc.max && value > comparation.vc.min) {
          return "normal"
      } else if(value > comparation.vc.max) {
          return 'more'
      } else if(value < comparation.vc.min) {
          return 'low'
      }
  } else if(tipe === 'tlc') {
      if(value < comparation.tlc.max && value > comparation.tlc.min) {
          return "normal"
      } else if(value > comparation.tlc.max) {
          return 'more'
      } else if(value < comparation.tlc.min) {
          return 'low'
      }
  } else if(tipe === 'tjn') {
      if(value < comparation.tjn.max && value > comparation.tjn.min) {
          return "normal"
      } else if(value > comparation.tjn.max) {
          return 'more'
      } else if(value < comparation.tjn.min) {
          return 'low'
      }
  } else if(tipe === 'tpdap') {
      if(value < comparation.tpdap.max && value > comparation.tpdap.min) {
          return "normal"
      } else if(value > comparation.tpdap.max) {
          return 'more'
      } else if(value < comparation.tpdap.min) {
          return 'low'
      }
  }
}

  return (
    <div  ref={componentRef} className='mb-7'>
      {data && (
        <div className='w-10/12 mx-auto bg-white shadow-md p-2 mt-2 '>
            <div className='w-8/12 bg-gray-100 mx-auto mt-8 p-2 pt-2 rounded'>
                <div className='flex space-x-32'>
                    <div>
                        <h5 className='m-3 font-semibold'>Nama: {data.data.name}</h5>
                        <h5 className='m-3 font-semibold'>Umur: {data.data.age}</h5>
                    </div>
                    <div>
                        <h5 className='m-3 font-semibold'>Gender: {data.data.gender}</h5>
                        <h5 className='m-3 font-semibold'>Tanggal: {resultDate}</h5>
                    </div>
                </div>
            </div>
            <div>
                
            <div class="relative overflow-x-auto shadow-md sm:rounded-lg mt-8">
                <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                    <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                        <tr>
                            <th scope="col" class="px-6 py-3">
                                Barang pengujian
                            </th>
                            <th scope="col" class="px-6 py-3">
                                Rentang Normal
                            </th>
                            <th scope="col" class="px-6 py-3">
                                Nilai Pengukuran yang sebenarnya
                            </th>
                            <th scope="col" class="px-6 py-3">
                                Hasil Pengujian
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
                            <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                              Kapasitas Vital VC
                            </th>
                            <td class="px-6 py-4 text-center">
                              3348 - 3529
                            </td>
                            <td class="px-6 py-4 text-center">
                                {data.data.vc}
                            </td>
                            <td class="px-6 py-4">
                                <div className={`${comparationResult('vc', data.data.vc) == 'normal' ? 'bg-primary' : comparationResult('vc', data.data.vc) == 'more' ? 'bg-yellow-400' : comparationResult('vc', data.data.vc) == 'low' ? 'bg-red-600' : ''} w-20 h-8 rounded-sm`}></div>
                            </td>
                        </tr>
                        <tr class="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
                            <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                              Kapasitas Total Paru TLC
                            </th>
                            <td class="px-6 py-4 text-center">
                              4301 - 4782
                            </td>
                            <td class="px-6 py-4 text-center">
                                {data.data.tlc}
                            </td>
                            <td class="px-6 py-4">
                            <div className={`${comparationResult('tlc', data.data.tlc) == 'normal' ? 'bg-primary' : comparationResult('tlc', data.data.tlc) == 'more' ? 'bg-yellow-400' : comparationResult('tlc', data.data.tlc) == 'low' ? 'bg-red-600' : ''} w-20 h-8 rounded-sm`}></div>
                            </td>
                        </tr>
                        <tr class="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
                            <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                              Tahanan Jalan Napas RAM
                            </th>
                            <td class="px-6 py-4 text-center">
                              1.374 - 1.709
                            </td>
                            <td class="px-6 py-4 text-center">
                                {data.data.tjn}
                            </td>
                            <td class="px-6 py-4">
                            <div className={`${comparationResult('tjn', data.data.tjn) == 'normal' ? 'bg-primary' : comparationResult('tjn', data.data.tjn) == 'more' ? 'bg-yellow-400' : comparationResult('tjn', data.data.tjn) == 'low' ? 'bg-red-600' : ''} w-20 h-8 rounded-sm`}></div>
                            </td>
                        </tr>
                        <tr class="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
                            <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                              Tekanan Parsial CO2 dalam Darah Arteri Paru
                            </th>
                            <td class="px-6 py-4 text-center">
                              17.903 - 21.012
                            </td>
                            <td class="px-6 py-4 text-center">
                                {data.data.tpdap}
                            </td>
                            <td class="px-6 py-4">
                            <div className={`${comparationResult('tpdap', data.data.tpdap) == 'normal' ? 'bg-primary' : comparationResult('tpdap', data.data.tpdap) == 'more' ? 'bg-yellow-400' : comparationResult('tpdap', data.data.tpdap) == 'low' ? 'bg-red-600' : ''} w-20 h-8 rounded-sm`}></div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div className='mt-4 text-right float-right flex gap-8'>
                <div>
                    <p className='font-semibold'>Kurang</p>
                    <div  className='w-12 h-4 bg-red-600 rounded'></div>
                </div>
                <div>
                    <p className='font-semibold'>Normal</p>
                    <div  className='w-12 h-4 bg-primary rounded'></div>
                </div>
                <div>
                    <p className='font-semibold'>Abnormal</p>
                    <div  className='w-12 h-4 bg-yellow-400 rounded'></div>
                </div>

                <div></div>
            </div>

            </div>
            <button className='bg-primary hover:bg-teal-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mx-2 my-8' onClick={printHandler}>Print</button>
            <Link to='/fp' ><button className='bg-yellow-400 hover:bg-yellow-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mx-1 mt-3'>Kembali</button></Link>
        </div>
      )}
    </div>
  )
}

export default FungsiPResult
