import React from 'react'
import { Link, json, useParams} from 'react-router-dom';
import { useState, useEffect, useRef } from 'react';
import { useReactToPrint } from 'react-to-print';

const SaluranPResult = () => {

    const { name } = useParams();
    const [data, setData] = useState(null);
    const componentRef = useRef();
    const comparation = {
        tsp: {
            min: 59.847,
            max: 65.234
        },
        gpl: {
            min: 58.425,
            max: 61.213
        },
        fpl: {
            min: 34.367,
            max: 35.642
        },
        gpu: {
            min: 133.437,
            max: 140.476
        },
        fpu: {
            min: 3.572,
            max: 6.483
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
              const headers = {
                'Content-type': 'application/json;',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Methods': 'GET, POST'
              };
      
            //   const response = await fetch(`http://localhost:8080/api/fsp/${name}`, { headers });
              const response = await fetch(`http://103.106.72.182:36013/api/fsp/${name}`, { headers });
              const jsonData = await response.json();
              setData(jsonData);
            } catch (error) {
              console.log(error);
            }
        }

        fetchData();
        
    }, [name])

    const printHandler = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: 'Data Sehat',
        onAfterPrint: () => alert('Print success'),
    });

    let resultDate
    if(data != null) {
        const dataString = data.data.createdAt
        const date = new Date(dataString)
        resultDate = date.toLocaleDateString('id-ID', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
        });
    }

    const comparationResult = (tipe, value) => {
        if(tipe === 'tsp') {
            if(value < comparation.tsp.max && value > comparation.tsp.min) {
                return "normal"
            } else if(value > comparation.tsp.max) {
                return 'more'
            } else if(value < comparation.tsp.min) {
                return 'low'
            }
        } else if(tipe === 'gpl') {
            if(value < comparation.gpl.max && value > comparation.gpl.min) {
                return "normal"
            } else if(value > comparation.gpl.max) {
                return 'more'
            } else if(value < comparation.gpl.min) {
                return 'low'
            }
        } else if(tipe === 'fpl') {
            if(value < comparation.fpl.max && value > comparation.fpl.min) {
                return "normal"
            } else if(value > comparation.fpl.max) {
                return 'more'
            } else if(value < comparation.fpl.min) {
                return 'low'
            }
        } else if(tipe === 'gpu') {
            if(value < comparation.gpu.max && value > comparation.gpu.min) {
                return "normal"
            } else if(value > comparation.gpu.max) {
                return 'more'
            } else if(value < comparation.gpu.min) {
                return 'low'
            }
        } else if(tipe === 'fpu') {
            if(value < comparation.fpu.max && value > comparation.fpu.min) {
                return "normal"
            } else if(value > comparation.fpu.max) {
                return 'more'
            } else if(value < comparation.fpu.min) {
                return 'low'
            }
        }
    }

  return (
    <div  ref={componentRef} className='mb-7'>
      {data && (
        <div className='w-10/12 mx-auto bg-white shadow-md p-2 mt-2 '>
            <div className='w-8/12 bg-gray-100 mx-auto mt-8 p-2 pt-2 rounded'>
                <div className='flex space-x-32'>
                    <div>
                        <h5 className='m-3 font-semibold'>Nama: {data.data.name}</h5>
                        <h5 className='m-3 font-semibold'>Umur: {data.data.age}</h5>
                    </div>
                    <div>
                        <h5 className='m-3 font-semibold'>Gender: {data.data.gender}</h5>
                        <h5 className='m-3 font-semibold'>Tanggal: {resultDate}</h5>
                    </div>
                </div>
            </div>
            <div>
                
            <div className="relative overflow-x-auto shadow-md sm:rounded-lg mt-8">
                <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                        <tr>
                            <th scope="col" className="px-6 py-3">
                                Barang pengujian
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Rentang Normal
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Nilai Pengukuran yang sebenarnya
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Hasil Pengujian
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr className="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
                            <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                Tingkat Sekresi Pespin
                            </th>
                            <td className="px-6 py-4 text-center">
                                59.847 - 65.234
                            </td>
                            <td className="px-6 py-4 text-center">
                                {data.data.tsp}
                            </td>
                            <td className="px-6 py-4">
                                <div className={`${comparationResult('tsp', data.data.tsp) == 'normal' ? 'bg-primary' : comparationResult('tsp', data.data.tsp) == 'more' ? 'bg-yellow-400' : comparationResult('tsp', data.data.tsp) == 'low' ? 'bg-red-600' : ''} w-20 h-8 rounded-sm`}></div>
                            </td>
                        </tr>
                        <tr className="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
                            <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                Gerakan Perstaltik Lambung
                            </th>
                            <td className="px-6 py-4 text-center">
                                58.425 - 61.213
                            </td>
                            <td className="px-6 py-4 text-center">
                                {data.data.gpl}
                            </td>
                            <td className="px-6 py-4">
                            <div className={`${comparationResult('gpl', data.data.gpl) == 'normal' ? 'bg-primary' : comparationResult('gpl', data.data.gpl) == 'more' ? 'bg-yellow-400' : comparationResult('gpl', data.data.gpl) == 'low' ? 'bg-red-600' : ''} w-20 h-8 rounded-sm`}></div>
                            </td>
                        </tr>
                        <tr className="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
                            <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                Fungsi Penyerapan Lambung
                            </th>
                            <td className="px-6 py-4 text-center">
                                34.367 - 35.642
                            </td>
                            <td className="px-6 py-4 text-center">
                                {data.data.fpl}
                            </td>
                            <td className="px-6 py-4">
                            <div className={`${comparationResult('fpl', data.data.fpl) == 'normal' ? 'bg-primary' : comparationResult('fpl', data.data.fpl) == 'more' ? 'bg-yellow-400' : comparationResult('fpl', data.data.fpl) == 'low' ? 'bg-red-600' : ''} w-20 h-8 rounded-sm`}></div>
                            </td>
                        </tr>
                        <tr className="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
                            <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                Gerakan peristaltik Usus Halus
                            </th>
                            <td className="px-6 py-4 text-center">
                                133.437 - 140.476
                            </td>
                            <td className="px-6 py-4 text-center">
                                {data.data.gpu}
                            </td>
                            <td className="px-6 py-4">
                            <div className={`${comparationResult('gpu', data.data.gpu) == 'normal' ? 'bg-primary' : comparationResult('gpu', data.data.gpu) == 'more' ? 'bg-yellow-400' : comparationResult('gpu', data.data.gpu) == 'low' ? 'bg-red-600' : ''} w-20 h-8 rounded-sm`}></div>
                            </td>
                        </tr>
                        <tr className="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
                            <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                Fungsi Penyerapan Usus Halus
                            </th>
                            <td className="px-6 py-4 text-center">
                                3.572 - 6.483
                            </td>
                            <td className="px-6 py-4 text-center">
                                {data.data.fpu}
                            </td>
                            <td className="px-6 py-4">
                            <div className={`${comparationResult('fpu', data.data.fpu) == 'normal' ? 'bg-primary' : comparationResult('fpu', data.data.fpu) == 'more' ? 'bg-yellow-400' : comparationResult('fpu', data.data.fpu) == 'low' ? 'bg-red-600' : ''} w-20 h-8 rounded-sm`}></div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div className='mt-4 text-right float-right flex gap-8'>
                <div>
                    <p className='font-semibold'>Kurang</p>
                    <div  className='w-12 h-4 bg-red-600 rounded'></div>
                </div>
                <div>
                    <p className='font-semibold'>Normal</p>
                    <div  className='w-12 h-4 bg-primary rounded'></div>
                </div>
                <div>
                    <p className='font-semibold'>Abnormal</p>
                    <div  className='w-12 h-4 bg-yellow-400 rounded'></div>
                </div>

                <div></div>
            </div>

            </div>
            <button className='bg-primary hover:bg-teal-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mx-2 my-8' onClick={printHandler}>Print</button>
            <Link to='/fsp' ><button className='bg-yellow-400 hover:bg-yellow-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mx-1 mt-3'>Kembali</button></Link>
        </div>
      )}
    </div>
  )
}

export default SaluranPResult
