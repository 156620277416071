import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Link, useHistory } from 'react-router-dom';

const FormMineral = () => {
    const [name, setName] = useState('');
    const [age, setAge] = useState('');
    const [gender, setGender] = useState('');
    const [kalsium, setKalsium] = useState('');
    const [zb, setZb] = useState('');
    const [zinc, setZinc] = useState('');
    const [selenium, setSelenium] = useState('');
    const [fosfor, setFosfor] = useState('');
    const [kalium, setKalium] = useState('');
    const [magnesium, setMagnesium] = useState('');
    const [tembaga, setTembaga] = useState('');
    const [kobalt, setKobalt] = useState('');


    const handleSubmit = async (e) => {
        e.preventDefault()

        const data = {
            "name" : name,
            "age" : parseInt(age),
            "gender" : gender,
            "kalsium" : parseFloat(kalsium),
            "zb": parseFloat(zb),
            "zinc": parseFloat(zinc),
            "selenium": parseFloat(selenium),
            "fosfor": parseFloat(fosfor),
            "kalium": parseFloat(kalium),
            "magnesium": parseFloat(magnesium),
            "tembaga": parseFloat(tembaga),
            "kobalt": parseFloat(kobalt),
        }

    
        // fetch('http://localhost:8080/api/mineral', {
        fetch('http://103.106.72.182:36013/api/mineral', {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-type': 'application/json;',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Methods': 'GET, POST'
            }         
        })
        .then(res => console.log(res))
        .catch(err => console.log(err))

    }

  return (
    <div>
        <h1 className='text-center font-bold text-2xl container mx-auto pt-8'>(Kandungan Mineral) Form Hasil Analisa</h1>
        <div className='bg-slate-100 shadow-md container mx-auto mt-8 p-8 w-1/2 mb-8'>
            <form className='flex' onSubmit={handleSubmit}>
                <div className='border-inherit w-96'>
                    <div className='p-3'>
                        <label htmlFor="name" className='block text-gray-700 font-bold mb-1'>Nama: </label>
                        <input
                         type='text'
                         name='name'
                         value={name}
                         id='name'
                         onChange={(e) => setName(e.target.value)} className='shadow appearance-none border rounded w-64 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'/>
                    </div>
                    <div className='p-3'>
                        <label htmlFor="gender" className="block text-gray-700 font-bold mb-1" >Gender: </label>
                        <select
                            id="gender"
                            name='gender'
                            value={gender}
                            onChange={(e) => setGender(e.target.value)}
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
                            <option value="">Pilih Jenis Kelamin</option>
                            <option value="Laki-laki">Laki-laki</option>
                            <option value="Perempuan">Perempuan</option>
                        </select>
                    </div>
                    <div className='p-3'>
                        <label htmlFor="age" className="block text-gray-700 font-bold mb-1">Umur: </label>
                        <input
                         type='number'
                         name='age'
                         value={age}
                         id='name'
                         min="0"
                         onChange={(e) => setAge(e.target.value)} className='shadow appearance-none border rounded w-64 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'/>
                    </div>

                    
                </div>

                <div>
                    <div className='p-3'>
                        <label htmlFor="kalsium" className="block text-gray-700 font-bold mb-1">Kalsium: </label>
                            <input
                            type='text'
                            name='kalsium'
                            value={kalsium}
                            id='kalsium'
                            onChange={(e) => setKalsium(e.target.value)} className='shadow appearance-none border rounded w-64 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'/>
                    </div>
                    <div className='p-3'>
                        <label htmlFor="zb" className="block text-gray-700 font-bold mb-1">Zat Besi: </label>
                                <input
                                type='text'
                                name='zb'
                                value={zb}
                                id='zb'
                                onChange={(e) => setZb(e.target.value)} className='shadow appearance-none border rounded w-64 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'/>
                    </div>
                    <div className='p-3'>
                        <label htmlFor="zinc" className="block text-gray-700 font-bold mb-1">Zinc: </label>
                                <input
                                type='text'
                                name='zinc'
                                value={zinc}
                                id='zinc'
                                onChange={(e) => setZinc(e.target.value)} className='shadow appearance-none border rounded w-64 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'/>
                    </div>
                    <div className='p-3'>
                        <label htmlFor="selenium" className="block text-gray-700 font-bold mb-1">Selenium: </label>
                                    <input
                                    type='text'
                                    name='selenium'
                                    value={selenium}
                                    id='selenium'
                                    onChange={(e) => setSelenium(e.target.value)} className='shadow appearance-none border rounded w-64 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'/>
                    </div>
                    <div className='p-3'>
                        <label htmlFor="fosfor" className="block text-gray-700 font-bold mb-1">Fosfor: </label>
                                    <input
                                    type='text'
                                    name='fosfor'
                                    value={fosfor}
                                    id='fosfor'
                                    onChange={(e) => setFosfor(e.target.value)} className='shadow appearance-none border rounded w-64 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'/>
                    </div>
                    <div className='p-3'>
                        <label htmlFor="kalium" className="block text-gray-700 font-bold mb-1">Kalium: </label>
                                    <input
                                    type='text'
                                    name='kalium'
                                    value={kalium}
                                    id='kalium'
                                    onChange={(e) => setKalium(e.target.value)} className='shadow appearance-none border rounded w-64 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'/>
                    </div>
                    <div className='p-3'>
                        <label htmlFor="magnesium" className="block text-gray-700 font-bold mb-1">Magnesium: </label>
                                    <input
                                    type='text'
                                    name='magnesium'
                                    value={magnesium}
                                    id='magnesium'
                                    onChange={(e) => setMagnesium(e.target.value)} className='shadow appearance-none border rounded w-64 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'/>
                    </div>
                    <div className='p-3'>
                    <label htmlFor="tembaga" className="block text-gray-700 font-bold mb-1">Tembaga: </label>
                                    <input
                                    type='text'
                                    name='tembaga'
                                    value={tembaga}
                                    id='tembaga'
                                    onChange={(e) => setTembaga(e.target.value)} className='shadow appearance-none border rounded w-64 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'/>
                    </div>
                    <div className='p-3'>
                    <label htmlFor="kobalt" className="block text-gray-700 font-bold mb-1">Kobalt: </label>
                                    <input
                                    type='text'
                                    name='kobalt'
                                    value={kobalt}
                                    id='kobalt'
                                    onChange={(e) => setKobalt(e.target.value)} className='shadow appearance-none border rounded w-64 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'/>
                    </div>
                    
                    <button type="submit" className="bg-primary hover:bg-teal-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mx-1 mt-8"> Submit </button>

                    <Link to={`/mr/${name}`} state={name}>
                    <button className='bg-lime-400 hover:bg-lime-500 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline'>Cek Hasil</button>
                    </Link>

                    <Link to='/'>
                    <button className='block mt-8 bg-yellow-400 hover:bg-yellow-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mx-1'>Kembali</button>
                    </Link>

                </div>
                
            </form>
        </div>
    </div>
  )
}

export default FormMineral
