import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Link, useHistory } from 'react-router-dom';

const FormSaluranP = () => {

    const [name, setName] = useState('');
    const [age, setAge] = useState('');
    const [gender, setGender] = useState('');
    const [tsp, setTsp] = useState('');
    const [gpl, setGpl] = useState('');
    const [fpl, setFpl] = useState('');
    const [gpu, setGpu] = useState('');
    const [fpu, setFpu] = useState('');

    const handleSubmit = async (e) => {
      e.preventDefault()

      const data = {
          "name" : name,
          "age" : parseInt(age),
          "gender" : gender,
          "tsp" : parseFloat(tsp),
          "gpl": parseFloat(gpl),
          "fpl": parseFloat(fpl),
          "gpu": parseFloat(gpu),
          "fpu": parseFloat(fpu)
      }

    //   fetch('http://localhost:8080/api/fsp', {
      fetch('http://103.106.72.182:36013/api/fsp', {
          method: 'POST',
          body: JSON.stringify(data),
          headers: {
              'Content-type': 'application/json;',
              'Access-Control-Allow-Origin': '*',
              'Access-Control-Allow-Methods': 'GET, POST'
          }         
      })
      .then(res => console.log(res))
      .catch(err => console.log(err))
  }

  return (
    <div>
      <h1 className='text-center font-bold text-2xl container mx-auto pt-8'>(Fungsi Saluran Pencernaan) Form Hasil Analisa</h1>
        <div className='bg-slate-100 shadow-md container mx-auto mt-8 p-8 w-1/2 mb-8'>
            <form className='flex' onSubmit={handleSubmit}>
                <div className='border-inherit w-96'>
                    <div className='p-3'>
                        <label htmlFor="name" className='block text-gray-700 font-bold mb-1'>Nama: </label>
                        <input
                         type='text'
                         name='name'
                         value={name}
                         id='name'
                         onChange={(e) => setName(e.target.value)} className='shadow appearance-none border rounded w-64 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'/>
                    </div>
                    <div className='p-3'>
                        <label htmlFor="gender" className="block text-gray-700 font-bold mb-1" >Gender: </label>
                        <select
                            id="gender"
                            name='gender'
                            value={gender}
                            onChange={(e) => setGender(e.target.value)}
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
                            <option value="">Pilih Jenis Kelamin</option>
                            <option value="Laki-laki">Laki-laki</option>
                            <option value="Perempuan">Perempuan</option>
                        </select>
                    </div>
                    <div className='p-3'>
                        <label htmlFor="age" className="block text-gray-700 font-bold mb-1">Umur: </label>
                        <input
                         type='number'
                         name='age'
                         value={age}
                         id='name'
                         min="0"
                         onChange={(e) => setAge(e.target.value)} className='shadow appearance-none border rounded w-64 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'/>
                    </div>

                    
                </div>

                <div>
                    <div className='p-3'>
                        <label htmlFor="tsp" className="block text-gray-700 font-bold mb-1">Tingkat Sekresi Pespin: </label>
                            <input
                            type='text'
                            name='tsp'
                            value={tsp}
                            id='tsp'
                            onChange={(e) => setTsp(e.target.value)} className='shadow appearance-none border rounded w-64 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'/>
                    </div>
                    <div className='p-3'>
                        <label htmlFor="gpl" className="block text-gray-700 font-bold mb-1">Gerakan Perstaltik Lambung: </label>
                                <input
                                type='text'
                                name='gpl'
                                value={gpl}
                                id='gpl'
                                onChange={(e) => setGpl(e.target.value)} className='shadow appearance-none border rounded w-64 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'/>
                    </div>
                    <div className='p-3'>
                        <label htmlFor="fpl" className="block text-gray-700 font-bold mb-1">Fungsi Penyerapan Lambung: </label>
                                <input
                                type='text'
                                name='fpl'
                                value={fpl}
                                id='fpl'
                                onChange={(e) => setFpl(e.target.value)} className='shadow appearance-none border rounded w-64 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'/>
                    </div>
                    <div className='p-3'>
                        <label htmlFor="gpu" className="block text-gray-700 font-bold mb-1">Gerakan peristaltik Usus Halus: </label>
                                    <input
                                    type='text'
                                    name='gpu'
                                    value={gpu}
                                    id='gpu'
                                    onChange={(e) => setGpu(e.target.value)} className='shadow appearance-none border rounded w-64 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'/>
                    </div>
                    <div className='p-3'>
                        <label htmlFor="fpu" className="block text-gray-700 font-bold mb-1">Fungsi Penyerapan Usus Halus: </label>
                                    <input
                                    type='text'
                                    name='fpu'
                                    value={fpu}
                                    id='fpu'
                                    onChange={(e) => setFpu(e.target.value)} className='shadow appearance-none border rounded w-64 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'/>
                    </div>
                    
                    <button type="submit" className="bg-primary hover:bg-teal-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mx-1 mt-8"> Submit </button>

                    <Link to={`/fspr/${name}`} state={name}>
                    <button className='bg-lime-400 hover:bg-lime-500 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline'>Cek Hasil</button>
                    </Link>

                    <Link to='/'>
                    <button className='block mt-8 bg-yellow-400 hover:bg-yellow-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mx-1'>Kembali</button>
                    </Link>

                </div>
                
            </form>
        </div>
    </div>
  )
}

export default FormSaluranP
