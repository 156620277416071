import React from 'react'
import { Link } from 'react-router-dom'

const ListAllData = () => {
  return (
    <div className='container'>
      <h1 className='text-center font-semibold text-4xl mt-4'>List Semua Data</h1>
        <div className='container rounded bg-slate-300 mt-8 mx-auto w-1/2 shadow-sm p-8 space-x-8 text-center'>
          <Link to='allhati'>
          <button className='bg-lime-400 hover:bg-lime-500 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline'>Data Hati</button>
          </Link>
          
          <Link to='allm'>
          <button className='bg-lime-400 hover:bg-lime-500 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline'>Data Mineral</button>
          </Link>

          <Link to='gdarah'>
          <button className='bg-lime-400 hover:bg-lime-500 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline'>Data Gula Darah</button>
          </Link>
        </div>

        <div className='container rounded bg-slate-300 mt-8 mx-auto w-1/2 shadow-sm p-8 space-x-8 text-center'>
          <Link to='rta'>
          <button className='bg-lime-400 hover:bg-lime-500 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline'>Rematik Tulang</button>
          </Link>
          
          <Link to='sro'>
          <button className='bg-lime-400 hover:bg-lime-500 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline'>Saraf Otak</button>
          </Link>

          <Link to='afp'>
          <button className='bg-lime-400 hover:bg-lime-500 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline'>Fungsi Paru</button>
          </Link>
        </div>

        <div className='container rounded bg-slate-300 mt-8 mx-auto w-1/2 shadow-sm p-8 space-x-8 text-center'>
          <Link to='afg'>
          <button className='bg-lime-400 hover:bg-lime-500 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline'>Data Ginjal</button>
          </Link>
          
          <Link to='afsp'>
          <button className='bg-lime-400 hover:bg-lime-500 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline'>Data Saluran Pencernaan</button>
          </Link>

          <Link to='apdjor'>
          <button className='bg-lime-400 hover:bg-lime-500 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mt-2'>Data Peredaran Darah Jantung & Otak</button>
          <br />
          <Link to='/' ><button className='bg-yellow-400 hover:bg-yellow-500 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mt-8'>Kembali</button></Link>
          </Link>
        </div>
        
    </div>
  )
}

export default ListAllData
