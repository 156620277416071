// import React from 'react'
import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Link, useHistory } from 'react-router-dom';
// import axios from 'axios';
// import Result from './Result';

const Formhati = () => {
  const [name, setName] = useState('');
  const [age, setAge] = useState('');
  const [gender, setGender] = useState('');
  const[mp, setMp] = useState('');
  const[fpe, setFpe] = useState('');
  const[fd, setFd] = useState('');
  const[fse, setFse] = useState('');
  const[klh, setKlh] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    

    const data = {
        "name" : name,
        "age" : parseInt(age),
        "gender" : gender,
        "mp" : parseFloat(mp),
        "fpe": parseFloat(fpe),
        "fd": parseFloat(fd),
        "fse": parseFloat(fse),
        "klh": parseFloat(klh)
    }
    
    // fetch('http://localhost:8080/api/hati', {
    fetch('http://103.106.72.182:36013/api/hati', {
         method:'POST',
         body: JSON.stringify(data),
         headers: {
            'Content-type': 'application/json;',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': 'GET, POST'
         },
        })
         .then((res) => console.log(res))
         .catch((err) => {
            console.log(err.message + "error bro");
        });
  };

  const url = `/resulth/${name}`;



  return (
      <div className="max-w-md mx-auto mt-10">
      <form onSubmit={handleSubmit} className="bg-white shadow-md rounded px-8 py-6 f">
        <div className="mb-4">
          <label htmlFor="name" className="block text-gray-700 font-bold mb-2">
            Nama:
          </label>
          <input
            type="text"
            id="name"
            name='name'
            value={name}
            onChange={(e) => setName(e.target.value)}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
        </div>
        <div className="mb-4">
          <label htmlFor="age" className="block text-gray-700 font-bold mb-2">
            Umur:
          </label>
          <input
            type="text"
            id="age"
            name='age'
            value={age}
            onChange={(e) => setAge(e.target.value)}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
        </div>
        <div className="mb-4">
          <label htmlFor="gender" className="block text-gray-700 font-bold mb-2">
            Jenis Kelamin:
          </label>
          <select
            id="gender"
            name='gender'
            value={gender}
            onChange={(e) => setGender(e.target.value)}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          >
            <option value="">Pilih Jenis Kelamin</option>
            <option value="Laki-laki">Laki-laki</option>
            <option value="Perempuan">Perempuan</option>
          </select>
        </div>
        <div className="mb-4">
          <label htmlFor="mp" className="block text-gray-700 font-bold mb-2">
            Metabolisme Protein:
          </label>
          <input
            type="text"
            id="mp"
            value={mp}
            name='mp'
            onChange={(e) => setMp(e.target.value)}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
        </div>
        <div className="mb-4">
          <label htmlFor="fpe" className="block text-gray-700 font-bold mb-2">
            Fungsi Produksi Energi:
          </label>
          <input
            type="text"
            id="fpe"
            name='fpe'
            value={fpe}
            onChange={(e) => setFpe(e.target.value)}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
        </div>
        <div className="mb-4">
          <label htmlFor="fd" className="block text-gray-700 font-bold mb-2">
            Fungsi Detoksifikasi:
          </label>
          <input
            type="text"
            id="fd"
            name='fd'
            value={fd}
            onChange={(e) => setFd(e.target.value)}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
        </div>
        <div className="mb-4">
          <label htmlFor="fse" className="block text-gray-700 font-bold mb-2">
            Fungsi Sekresi Empedu:
          </label>
          <input
            type="text"
            id="fse"
            name='fse'
            value={fse}
            onChange={(e) => setFse(e.target.value)}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
        </div>
        <div className="mb-4">
          <label htmlFor="klh" className="block text-gray-700 font-bold mb-2">
            Kandungan Lemak Hati:
          </label>
          <input
            type="text"
            id="klh"
            name='klh'
            value={klh}
            onChange={(e) => setKlh(e.target.value)}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
        </div>
        <div className="flex items-center justify-center">
          <button
            type="submit"
            className="bg-primary hover:bg-teal-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mx-1">
            Submit
          </button>
          <Link to={url} state={{name}} ><button 
            type="submit"
            className="bg-lime-400 hover:bg-lime-500 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          >
            Cek Hasil
          </button></Link>
          <Link to='/' ><button className='bg-yellow-400 hover:bg-yellow-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mx-1'>Kembali</button></Link>
        </div>
      </form>
    </div>
  )
}

export default Formhati



