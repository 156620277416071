import React from 'react'
import { Link, json, useParams} from 'react-router-dom';
import { useState, useEffect, useRef } from 'react';
import { useReactToPrint } from 'react-to-print';

const FungsiGinjalResult = () => {

    const { name } = useParams();
    const [data, setData] = useState(null);
    const componentRef = useRef();
    const comparation = {
        ku: {
            min: 2.762,
            max: 5.424
        },
        kau: {
            min: 1.435,
            max: 1.987
        },
        iund: {
            min: 4.725,
            max: 8.631
        },
        ip: {
            min: 1.571,
            max: 4.0079
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
              const headers = {
                'Content-type': 'application/json;',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Methods': 'GET, POST'
              };
      
            //   const response = await fetch(`http://localhost:8080/api/fg/${name}`, { headers });
              const response = await fetch(`http://103.106.72.182:36013/api/fg/${name}`, { headers });
              const jsonData = await response.json();
              setData(jsonData);
            } catch (error) {
              console.log(error);
            }
        }

        fetchData();
        
    }, [name])

    const printHandler = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: 'Data Sehat',
        onAfterPrint: () => alert('Print success'),
    });

    let resultDate
    if(data != null) {
        const dataString = data.data.createdAt
        const date = new Date(dataString)
        resultDate = date.toLocaleDateString('id-ID', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
        });
    }

    const comparationResult = (tipe, value) => {
        if(tipe === 'ku') {
            if(value < comparation.ku.max && value > comparation.ku.min) {
                return "normal"
            } else if(value > comparation.ku.max) {
                return 'more'
            } else if(value < comparation.ku.min) {
                return 'low'
            }
        } else if(tipe === 'kau') {
            if(value < comparation.kau.max && value > comparation.kau.min) {
                return "normal"
            } else if(value > comparation.kau.max) {
                return 'more'
            } else if(value < comparation.kau.min) {
                return 'low'
            }
        } else if(tipe === 'iund') {
            if(value < comparation.iund.max && value > comparation.iund.min) {
                return "normal"
            } else if(value > comparation.iund.max) {
                return 'more'
            } else if(value < comparation.iund.min) {
                return 'low'
            }
        } else if(tipe === 'ip') {
            if(value < comparation.ip.max && value > comparation.ip.min) {
                return "normal"
            } else if(value > comparation.ip.max) {
                return 'more'
            } else if(value < comparation.ip.min) {
                return 'low'
            }
        }
    }

  return (
    <div  ref={componentRef} className='mb-7'>
      {data && (
        <div className='w-10/12 mx-auto bg-white shadow-md p-2 mt-2 '>
            <div className='w-8/12 bg-gray-100 mx-auto mt-8 p-2 pt-2 rounded'>
                <div className='flex space-x-32'>
                    <div>
                        <h5 className='m-3 font-semibold'>Nama: {data.data.name}</h5>
                        <h5 className='m-3 font-semibold'>Umur: {data.data.age}</h5>
                    </div>
                    <div>
                        <h5 className='m-3 font-semibold'>Gender: {data.data.gender}</h5>
                        <h5 className='m-3 font-semibold'>Tanggal: {resultDate}</h5>
                    </div>
                </div>
            </div>
            <div>
                
            <div className="relative overflow-x-auto shadow-md sm:rounded-lg mt-8">
                <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                        <tr>
                            <th scope="col" className="px-6 py-3">
                                Barang pengujian
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Rentang Normal
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Nilai Pengukuran yang sebenarnya
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Hasil Pengujian
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr className="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
                            <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                Kadar Urobilinogen
                            </th>
                            <td className="px-6 py-4 text-center">
                                2.762 - 5.424
                            </td>
                            <td className="px-6 py-4 text-center">
                                {data.data.ku}
                            </td>
                            <td className="px-6 py-4">
                                <div className={`${comparationResult('ku', data.data.ku) == 'normal' ? 'bg-primary' : comparationResult('ku', data.data.ku) == 'more' ? 'bg-yellow-400' : comparationResult('ku', data.data.ku) == 'low' ? 'bg-red-600' : ''} w-20 h-8 rounded-sm`}></div>
                            </td>
                        </tr>
                        <tr className="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
                            <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                Kadar Asam Urat
                            </th>
                            <td className="px-6 py-4 text-center">
                                1.435 - 1.987
                            </td>
                            <td className="px-6 py-4 text-center">
                                {data.data.kau}
                            </td>
                            <td className="px-6 py-4">
                            <div className={`${comparationResult('kau', data.data.kau) == 'normal' ? 'bg-primary' : comparationResult('kau', data.data.kau) == 'more' ? 'bg-yellow-400' : comparationResult('kau', data.data.kau) == 'low' ? 'bg-red-600' : ''} w-20 h-8 rounded-sm`}></div>
                            </td>
                        </tr>
                        <tr className="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
                            <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                Indeks Urea Nitrogen Dalam Darah
                            </th>
                            <td className="px-6 py-4 text-center">
                                4.725 - 8.631
                            </td>
                            <td className="px-6 py-4 text-center">
                                {data.data.iund}
                            </td>
                            <td className="px-6 py-4">
                            <div className={`${comparationResult('iund', data.data.iund) == 'normal' ? 'bg-primary' : comparationResult('iund', data.data.iund) == 'more' ? 'bg-yellow-400' : comparationResult('iund', data.data.iund) == 'low' ? 'bg-red-600' : ''} w-20 h-8 rounded-sm`}></div>
                            </td>
                        </tr>
                        <tr className="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
                            <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                Indeks Proteinuria
                            </th>
                            <td className="px-6 py-4 text-center">
                                1.571 - 4.0079
                            </td>
                            <td className="px-6 py-4 text-center">
                                {data.data.ip}
                            </td>
                            <td className="px-6 py-4">
                            <div className={`${comparationResult('ip', data.data.ip) == 'normal' ? 'bg-primary' : comparationResult('ip', data.data.ip) == 'more' ? 'bg-yellow-400' : comparationResult('ip', data.data.ip) == 'low' ? 'bg-red-600' : ''} w-20 h-8 rounded-sm`}></div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div className='mt-4 text-right float-right flex gap-8'>
                <div>
                    <p className='font-semibold'>Kurang</p>
                    <div  className='w-12 h-4 bg-red-600 rounded'></div>
                </div>
                <div>
                    <p className='font-semibold'>Normal</p>
                    <div  className='w-12 h-4 bg-primary rounded'></div>
                </div>
                <div>
                    <p className='font-semibold'>Abnormal</p>
                    <div  className='w-12 h-4 bg-yellow-400 rounded'></div>
                </div>

                <div></div>
            </div>

            </div>
            <button className='bg-primary hover:bg-teal-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mx-2 my-8' onClick={printHandler}>Print</button>
            <Link to='/fg' ><button className='bg-yellow-400 hover:bg-yellow-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mx-1 mt-3'>Kembali</button></Link>
        </div>
      )}
    </div>
  )
}

export default FungsiGinjalResult
