import React from 'react';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const AllHati = () => {
    const [data, setData] = useState(null);
    const [rm, setRm] = useState('')

    useEffect(() => {
        // fetch('http://localhost:8080/api/hati')
        fetch('http://103.106.72.182:36013/api/hati')
        .then(res => {
            if(res.ok) {
                return res.json()
            } 
            throw res
        })
        .then(data => {
            setData(data)
            setRm(data.name)
        })
        .catch(err => {
            console.log("error fetching "+ err)
        })


    }, [])

    function dateChange(dateChange) {
        const dataString = dateChange
        const date = new Date(dataString)
        return date.toLocaleDateString('id-ID', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
        });
    }

    // console.log(rm)
    const handleSubmit = () => {
        // fetch(`http://localhost:8080/api/hati/${rm}`, {
        fetch(`http://103.106.72.182:36013/api/hati/${rm}`, {
            method:'DELETE',
            headers: {
               'Content-type': 'application/json;',
               'Access-Control-Allow-Origin': '*',
               'Access-Control-Allow-Methods': 'GET, POST, DELETE'
            },
           })
            .then((res) => console.log(res))
            .catch((err) => {
               console.log(err.message + "error bro");
           });
    }

    let result
    if(data != null) {
       result = data.data.map(post => {
        return(
            <tr key={post.name} className="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
                            <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                {post.name}
                            </th>
                            <td className="px-6 py-4 text-center">
                                {post.age}
                            </td>
                            <td className="px-6 py-4 text-center">
                               {post.gender}
                            </td>
                            <td className="px-6 py-4">
                               {dateChange(post.createdAt)}
                            </td>
                            <td className="px-6 py-4">

                            <Link to={`/resulth/${post.name}`}><button className='bg-yellow-400 hover:bg-yellow-600 text-white font-bold py-1 px-1 rounded focus:outline-none focus:shadow-outline mx-1 mt-3'>Lihat</button></Link>

                            </td>
                        </tr>
        )
    })
    } 

  return (
    <div  className='mb-7'>
        <h1 className='text-center my-4 font-bold text-2xl'>Data Fungsi Hati</h1>

        <form className='text-center'  onSubmit={handleSubmit}>
            <div className="mb-4">
            <label htmlFor="mp" className="block text-gray-700 font-bold mb-2">
                Masukkan Nama Data untuk dihapus:
            </label>
            <input
                type="text"
                id="rm"
                value={rm}
                name='rm'
                onChange={(e) => setRm(e.target.value)}
                className="shadow appearance-none border rounded w-44 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
            </div>
            <button type="submit" className="bg-red-600 hover:bg-red-800 text-white rounded font-bold py-1 px-1 focus:outline-none focus:shadow-outline mx-1 mb-8"> Hapus </button>
        </form>

      {data && (
        <div className='w-10/12 mx-auto bg-white shadow-md p-2 mt-2 '>
            
            <div>  
            <div className="relative overflow-x-auto shadow-md sm:rounded-lg mt-8">
                <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                        <tr>
                            <th scope="col" className="px-6 py-3">
                                Nama
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Umur
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Gender
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Tanggal
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Aksi
                            </th>
                        </tr>
                    </thead>
                    <tbody>

                        {result}
                        
                    </tbody>
                </table>
            </div>

            </div>
            
            <Link to='/listall' ><button className='bg-yellow-400 hover:bg-yellow-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mx-1 mt-3'>Kembali</button></Link>
        </div>
      )}
    </div>
  )
}

export default AllHati
