import React from 'react'
import { Link, json, useParams} from 'react-router-dom';
import { useState, useEffect, useRef } from 'react';
import { useReactToPrint } from 'react-to-print';

const MineralResult = () => {
    // kalsium, zb, zinc, selenium, fosfor, kalium, magnesium, tembaga, kobalt

    const { name } = useParams();
    const [data, setData] = useState(null);
    const componentRef = useRef();
    const comparation = {
        kalsium: {
            min: 1.219,
            max: 3.021
        },
        zb: {
            min: 1.151,
            max: 1.847
        },
        zinc: {
            min: 1.143,
            max: 1.989
        },
        selenium: {
            min: 0.847,
            max: 2.045
        },
        fosfor: {
            min: 1.195,
            max: 2.134
        },
        kalium: {
            min: 0.689,
            max: 0.987
        },
        magnesium: {
            min: 0.568,
            max: 0.992
        },
        tembaga: {
            min: 0.474,
            max: 0.749
        },
        kobalt: {
            min: 2.236,
            max: 5.531
        },
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
              const headers = {
                'Content-type': 'application/json;',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Methods': 'GET, POST'
              };
      
            //   const response = await fetch(`http://localhost:8080/api/mineral/${name}`, { headers });
              const response = await fetch(`http://103.106.72.182:36013/api/mineral/${name}`, { headers });
              const jsonData = await response.json();
              setData(jsonData);
            } catch (error) {
              console.log(error);
            }
        }

        fetchData();
        
    }, [name])

    const printHandler = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: 'Data Sehat',
        onAfterPrint: () => alert('Print success'),
    });

    let resultDate
    if(data != null) {
        const dataString = data.data.createdAt
        const date = new Date(dataString)
        resultDate = date.toLocaleDateString('id-ID', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
        });
    }

    // kalsium, zb, zinc, selenium, fosfor, kalium, magnesium, tembaga, kobalt
    const comparationResult = (tipe, value) => {
        if(tipe === 'kalsium') {
            if(value < comparation.kalsium.max && value > comparation.kalsium.min) {
                return "normal"
            } else if(value > comparation.kalsium.max) {
                return 'more'
            } else if(value < comparation.kalsium.min) {
                return 'low'
            }
        } else if(tipe === 'zb') {
            if(value < comparation.zb.max && value > comparation.zb.min) {
                return "normal"
            } else if(value > comparation.zb.max) {
                return 'more'
            } else if(value < comparation.zb.min) {
                return 'low'
            }
        } else if(tipe === 'zinc') {
            if(value < comparation.zinc.max && value > comparation.zinc.min) {
                return "normal"
            } else if(value > comparation.zinc.max) {
                return 'more'
            } else if(value < comparation.zinc.min) {
                return 'low'
            }
        } else if(tipe === 'selenium') {
            if(value < comparation.selenium.max && value > comparation.selenium.min) {
                return "normal"
            } else if(value > comparation.selenium.max) {
                return 'more'
            } else if(value < comparation.selenium.min) {
                return 'low'
            }
        } else if(tipe === 'fosfor') {
            if(value < comparation.fosfor.max && value > comparation.fosfor.min) {
                return "normal"
            } else if(value > comparation.fosfor.max) {
                return 'more'
            } else if(value < comparation.fosfor.min) {
                return 'low'
            }
        } else if(tipe === 'kalium') {
            if(value < comparation.kalium.max && value > comparation.kalium.min) {
                return "normal"
            } else if(value > comparation.kalium.max) {
                return 'more'
            } else if(value < comparation.kalium.min) {
                return 'low'
            }
        } else if(tipe === 'magnesium') {
            if(value < comparation.magnesium.max && value > comparation.magnesium.min) {
                return "normal"
            } else if(value > comparation.magnesium.max) {
                return 'more'
            } else if(value < comparation.magnesium.min) {
                return 'low'
            }
        } else if(tipe === 'tembaga') {
            if(value < comparation.tembaga.max && value > comparation.tembaga.min) {
                return "normal"
            } else if(value > comparation.tembaga.max) {
                return 'more'
            } else if(value < comparation.tembaga.min) {
                return 'low'
            }
        } else if(tipe === 'kobalt') {
            if(value < comparation.kobalt.max && value > comparation.kobalt.min) {
                return "normal"
            } else if(value > comparation.kobalt.max) {
                return 'more'
            } else if(value < comparation.kobalt.min) {
                return 'low'
            }
        }
    }


  return (
    <div  ref={componentRef} className='mb-7'>
      {data && (
        <div className='w-10/12 mx-auto bg-white shadow-md p-2 mt-2 '>
            <div className='w-8/12 bg-gray-100 mx-auto mt-8 p-2 pt-2 rounded'>
                <div className='flex space-x-32'>
                    <div>
                        <h5 className='m-3 font-semibold'>Nama: {data.data.name}</h5>
                        <h5 className='m-3 font-semibold'>Umur: {data.data.age}</h5>
                    </div>
                    <div>
                        <h5 className='m-3 font-semibold'>Gender: {data.data.gender}</h5>
                        <h5 className='m-3 font-semibold'>Tanggal: {resultDate}</h5>
                    </div>
                </div>
            </div>
            <div>
                
            <div class="relative overflow-x-auto shadow-md sm:rounded-lg mt-8">
                <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                    <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                        <tr>
                            <th scope="col" class="px-6 py-3">
                                Barang pengujian
                            </th>
                            <th scope="col" class="px-6 py-3">
                                Rentang Normal
                            </th>
                            <th scope="col" class="px-6 py-3">
                                Nilai Pengukuran yang sebenarnya
                            </th>
                            <th scope="col" class="px-6 py-3">
                                Hasil Pengujian
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
                            <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                Kalsium
                            </th>
                            <td class="px-6 py-4 text-center">
                                1.219 - 3.021
                            </td>
                            <td class="px-6 py-4 text-center">
                                {data.data.kalsium}
                            </td>
                            <td class="px-6 py-4">
                                <div className={`${comparationResult('kalsium', data.data.kalsium) == 'normal' ? 'bg-primary' : comparationResult('kalsium', data.data.kalsium) == 'more' ? 'bg-yellow-400' : comparationResult('kalsium', data.data.kalsium) == 'low' ? 'bg-red-600' : ''} w-20 h-8 rounded-sm`}></div>
                            </td>
                        </tr>
                        <tr class="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
                            <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                Zat besi
                            </th>
                            <td class="px-6 py-4 text-center">
                                1.151 - 1.847
                            </td>
                            <td class="px-6 py-4 text-center">
                                {data.data.zb}
                            </td>
                            <td class="px-6 py-4">
                            <div className={`${comparationResult('zb', data.data.zb) == 'normal' ? 'bg-primary' : comparationResult('zb', data.data.zb) == 'more' ? 'bg-yellow-400' : comparationResult('zb', data.data.zb) == 'low' ? 'bg-red-600' : ''} w-20 h-8 rounded-sm`}></div>
                            </td>
                        </tr>
                        <tr class="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
                            <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                Zinc
                            </th>
                            <td class="px-6 py-4 text-center">
                                1.143 - 1.989
                            </td>
                            <td class="px-6 py-4 text-center">
                                {data.data.zinc}
                            </td>
                            <td class="px-6 py-4">
                            <div className={`${comparationResult('zinc', data.data.zinc) == 'normal' ? 'bg-primary' : comparationResult('zinc', data.data.zinc) == 'more' ? 'bg-yellow-400' : comparationResult('zinc', data.data.zinc) == 'low' ? 'bg-red-600' : ''} w-20 h-8 rounded-sm`}></div>
                            </td>
                        </tr>
                        <tr class="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
                            <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                Selenium
                            </th>
                            <td class="px-6 py-4 text-center">
                                0.847 - 2.045
                            </td>
                            <td class="px-6 py-4 text-center">
                                {data.data.selenium}
                            </td>
                            <td class="px-6 py-4">
                            <div className={`${comparationResult('selenium', data.data.selenium) == 'normal' ? 'bg-primary' : comparationResult('selenium', data.data.selenium) == 'more' ? 'bg-yellow-400' : comparationResult('selenium', data.data.selenium) == 'low' ? 'bg-red-600' : ''} w-20 h-8 rounded-sm`}></div>
                            </td>
                        </tr>
                        <tr class="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
                            <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                Fosfor
                            </th>
                            <td class="px-6 py-4 text-center">
                                1.195 - 2.134
                            </td>
                            <td class="px-6 py-4 text-center">
                                {data.data.fosfor}
                            </td>
                            <td class="px-6 py-4">
                            <div className={`${comparationResult('fosfor', data.data.fosfor) == 'normal' ? 'bg-primary' : comparationResult('fosfor', data.data.fosfor) == 'more' ? 'bg-yellow-400' : comparationResult('fosfor', data.data.fosfor) == 'low' ? 'bg-red-600' : ''} w-20 h-8 rounded-sm`}></div>
                            </td>
                        </tr>
                        <tr class="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
                            <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                Kalium
                            </th>
                            <td class="px-6 py-4 text-center">
                                0.689 - 0.987
                            </td>
                            <td class="px-6 py-4 text-center">
                                {data.data.kalium}
                            </td>
                            <td class="px-6 py-4">
                            <div className={`${comparationResult('kalium', data.data.kalium) == 'normal' ? 'bg-primary' : comparationResult('kalium', data.data.kalium) == 'more' ? 'bg-yellow-400' : comparationResult('kalium', data.data.kalium) == 'low' ? 'bg-red-600' : ''} w-20 h-8 rounded-sm`}></div>
                            </td>
                        </tr>
                        <tr class="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
                            <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                Magnesium
                            </th>
                            <td class="px-6 py-4 text-center">
                                0.568 - 0.992
                            </td>
                            <td class="px-6 py-4 text-center">
                                {data.data.magnesium}
                            </td>
                            <td class="px-6 py-4">
                            <div className={`${comparationResult('magnesium', data.data.magnesium) == 'normal' ? 'bg-primary' : comparationResult('magnesium', data.data.magnesium) == 'more' ? 'bg-yellow-400' : comparationResult('magnesium', data.data.magnesium) == 'low' ? 'bg-red-600' : ''} w-20 h-8 rounded-sm`}></div>
                            </td>
                        </tr>
                        <tr class="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
                            <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                Tembaga
                            </th>
                            <td class="px-6 py-4 text-center">
                                0.474 - 0.749
                            </td>
                            <td class="px-6 py-4 text-center">
                                {data.data.tembaga}
                            </td>
                            <td class="px-6 py-4">
                            <div className={`${comparationResult('tembaga', data.data.tembaga) == 'normal' ? 'bg-primary' : comparationResult('tembaga', data.data.tembaga) == 'more' ? 'bg-yellow-400' : comparationResult('tembaga', data.data.tembaga) == 'low' ? 'bg-red-600' : ''} w-20 h-8 rounded-sm`}></div>
                            </td>
                        </tr>
                        <tr class="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
                            <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                Kobalt
                            </th>
                            <td class="px-6 py-4 text-center">
                                2.236 - 5.531
                            </td>
                            <td class="px-6 py-4 text-center">
                                {data.data.kobalt}
                            </td>
                            <td class="px-6 py-4">
                            <div className={`${comparationResult('kobalt', data.data.kobalt) == 'normal' ? 'bg-primary' : comparationResult('kobalt', data.data.kobalt) == 'more' ? 'bg-yellow-400' : comparationResult('kobalt', data.data.kobalt) == 'low' ? 'bg-red-600' : ''} w-20 h-8 rounded-sm`}></div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div className='mt-4 text-right float-right flex gap-8'>
                <div>
                    <p className='font-semibold'>Kurang</p>
                    <div  className='w-12 h-4 bg-red-600 rounded'></div>
                </div>
                <div>
                    <p className='font-semibold'>Normal</p>
                    <div  className='w-12 h-4 bg-primary rounded'></div>
                </div>
                <div>
                    <p className='font-semibold'>Abnormal</p>
                    <div  className='w-12 h-4 bg-yellow-400 rounded'></div>
                </div>

                <div></div>
            </div>

            </div>
            <button className='bg-primary hover:bg-teal-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mx-2 my-8' onClick={printHandler}>Print</button>
            <Link to='/mineral' ><button className='bg-yellow-400 hover:bg-yellow-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mx-1 mt-3'>Kembali</button></Link>
        </div>
      )}
    </div>
  )
}

export default MineralResult
