import React from 'react'
import { Link, json, useParams} from 'react-router-dom';
import { useState, useEffect, useRef } from 'react';
import { useReactToPrint } from 'react-to-print';

const Result = () => {
    // mp, fpe, fd, fse, klh,
    const { name } = useParams();
    const [data, setData] = useState(null);
    const componentRef = useRef();
    const ping = {
        mp : {
            min: 116.34,
            max: 220.621
        },
        fpe: {
            min: 0.713,
            max: 0.992
        },
        fd: {
            min: 0.202,
            max: 0.991
        },
        fse: {
            min: 0.432,
            max: 0.826
        },
        klh: {
            min: 0.097,
            max: 0.419
        }
    }

    // console.log(ping.mp.min)

    useEffect(() => {
        const fetchData = async () => {
            try {
              const headers = {
                'Content-type': 'application/json;',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Methods': 'GET, POST'
              };
      
            //   const response = await fetch(`http://localhost:8080/api/hati/${name}`, { headers });
              const response = await fetch(`http://103.106.72.182:36013/api/hati/${name}`, { headers });
              const jsonData = await response.json();
              setData(jsonData);
            } catch (error) {
              console.log(error);
            }
        }

        fetchData();
        
    }, [name])

      

    const printHandler = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: 'Data Sehat',
        onAfterPrint: () => alert('Print success'),
    });

    const hasil = (tipe, value) => {
        if(tipe === 'mp') {
            if(value < ping.mp.max && value > ping.mp.min) {
                return 'normal'
            } else if(value > ping.mp.max) {
                return 'more'
            } else if(value < ping.mp.min) {
                return 'low'
            }
        } else if(tipe === 'fpe') {
            if(value < ping.fpe.max && value > ping.fpe.min) {
                return 'normal'
            } else if(value > ping.fpe.max) {
                return 'more'
            } else if(value < ping.fpe.min) {
                return 'low'
            }
        } else if(tipe === 'fd') {
            if(value < ping.fd.max && value > ping.fd.min) {
                return 'normal'
            } else if(value > ping.fd.max) {
                return 'more'
            } else if(value < ping.fd.min) {
                return 'low'
            }
        } else if(tipe === 'fse') {
            if(value < ping.fse.max && value > ping.fse.min) {
                return 'normal'
            } else if(value > ping.fse.max) {
                return 'more'
            } else if(value < ping.fse.min) {
                return 'low'
            }
        } else if(tipe === 'klh') {
            if(value < ping.klh.max && value > ping.klh.min) {
                return 'normal'
            } else if(value > ping.klh.max) {
                return 'more'
            } else if(value < ping.klh.min) {
                return 'low'
            }
        }
    }

  return (
    <div ref={componentRef}>
        {data &&  (
        <div className='container mx-auto p-16 shadow-xl'>
            <div className='border rounded-lg'>
                <p className='w-1/2 text-center mx-auto font-bold'>
                    Nama: <input type='text' value={data.data.name} readOnly />
                    Gender: <input type='text' value={data.data.gender} readOnly />
                </p>
                <p className='w-1/2 text-center mx-auto font-bold'>
                    Umur: <input type='text' value={data.data.age} readOnly />
                    Gejala: <input type='text' value='Sakit Hati' readOnly />
                </p>
            </div>
            <table className="min-w-full bg-white border border-gray-300 mt-12">
                <thead>
                    <tr>
                    <th className="px-6 py-3 border-b border-gray-300 text-left font-semibold">Barang Pengujian</th>
                    <th className="px-6 py-3 border-b border-gray-300 text-left font-semibold">Rentang Normal</th>
                    <th className="px-6 py-3 border-b border-gray-300 text-left font-semibold">Nilai Pengukuran yang sebenarnya</th>
                    <th className="px-6 py-3 border-b border-gray-300 text-left font-semibold">Hasil Pengujian</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td className="px-6 py-4 border-b border-gray-300">Metabolisme Protein</td>
                        <td className="px-6 py-4 border-b border-gray-300 text-center">116.34 - 220.621</td>
                        <td className="px-6 py-4 border-b border-gray-300 text-center">{data.data.mp}</td>
                        <td className="px-6 py-4 border-b border-gray-300">
                        <div className={`${hasil('mp', data.data.mp) == 'normal' ? 'bg-primary' : hasil('mp', data.data.mp) == 'more' ? 'bg-yellow-400' : hasil('mp', data.data.mp) == 'low' ? 'bg-red-600' : ''} w-20 h-8 rounded-sm`}></div>
                        </td>
                    </tr>
                    <tr>
                        <td className="px-6 py-4 border-b border-gray-300">Fungsi Produksi Energi</td>
                        <td className="px-6 py-4 border-b border-gray-300 text-center">0.713-0.992</td>
                        <td className="px-6 py-4 border-b border-gray-300 text-center">{data.data.fpe}</td>
                        <td className="px-6 py-4 border-b border-gray-300">
                        <div className={`${hasil('fpe', data.data.fpe) == 'normal' ? 'bg-primary' : hasil('fpe', data.data.fpe) == 'more' ? 'bg-yellow-400' : hasil('fpe', data.data.fpe) == 'low' ? 'bg-red-600' : ''} w-20 h-8 rounded-sm`}></div>
                        </td>
                    </tr>
                    <tr>
                        <td className="px-6 py-4 border-b border-gray-300">Fungsi Detoksifikasi</td>
                        <td className="px-6 py-4 border-b border-gray-300 text-center">0.202 - 0.991</td>
                        <td className="px-6 py-4 border-b border-gray-300 text-center">{data.data.fd}</td>
                        <td className="px-6 py-4 border-b border-gray-300">
                        <div className={`${hasil('fd', data.data.fd) == 'normal' ? 'bg-primary' : hasil('fd', data.data.fd) == 'more' ? 'bg-yellow-400' : hasil('fd', data.data.fd) == 'low' ? 'bg-red-600' : ''} w-20 h-8 rounded-sm`}></div>
                        </td>
                    </tr>
                    <tr>
                        <td className="px-6 py-4 border-b border-gray-300">Fungsi sekresi empedu</td>
                        <td className="px-6 py-4 border-b border-gray-300 text-center">0.432 - 0.826</td>
                        <td className="px-6 py-4 border-b border-gray-300 text-center">{data.data.fse}</td>
                        <td className="px-6 py-4 border-b border-gray-300">
                        <div className={`${hasil('fse', data.data.fse) == 'normal' ? 'bg-primary' : hasil('fse', data.data.fse) == 'more' ? 'bg-yellow-400' : hasil('fse', data.data.fse) == 'low' ? 'bg-red-600' : ''} w-20 h-8 rounded-sm`}></div>
                        </td>
                    </tr>
                    <tr>
                        <td className="px-6 py-4 border-b border-gray-300">Kandaungan lemak hati</td>
                        <td className="px-6 py-4 border-b border-gray-300 text-center">0.097 - 0.419</td>
                        <td className="px-6 py-4 border-b border-gray-300 text-center">{data.data.klh}</td>
                        <td className="px-6 py-4 border-b border-gray-300">
                        <div className={`${hasil('klh', data.data.klh) == 'normal' ? 'bg-primary' : hasil('klh', data.data.klh) == 'more' ? 'bg-yellow-400' : hasil('klh', data.data.klh) == 'low' ? 'bg-red-600' : ''} w-20 h-8 rounded-sm`}></div>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div className='mt-4 text-right float-right flex gap-8'>
                <div>
                    <p className='font-semibold'>Kurang</p>
                    <div  className='w-12 h-4 bg-red-600 rounded'></div>
                </div>
                <div>
                    <p className='font-semibold'>Normal</p>
                    <div  className='w-12 h-4 bg-primary rounded'></div>
                </div>
                <div>
                    <p className='font-semibold'>Abnormal</p>
                    <div  className='w-12 h-4 bg-yellow-400 rounded'></div>
                </div>

                <div></div>
            </div>
            <Link to='/hati' ><button className='bg-yellow-400 hover:bg-yellow-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mx-1 mt-3'>Kembali</button></Link>
            <button className='bg-primary hover:bg-teal-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mx-1 mt-3' onClick={printHandler}>Print</button>
        </div>
        )}  
    </div>
  )
}

export default Result
