import React from 'react'
import { Link, json, useParams} from 'react-router-dom';
import { useState, useEffect, useRef } from 'react';
import { useReactToPrint } from 'react-to-print';

const RTResult = () => {

    const { name } = useParams();
    const [data, setData] = useState(null);
    const componentRef = useRef();
    const comparation = {
        tptl: {
            min: 421,
            max: 490
        },
        tptb: {
            min: 4.326,
            max: 7.531
        },
        kpt: {
            min: 2.954,
            max: 5.543
        },
        ko: {
            min: 2.019,
            max: 4.721
        },
        kr: {
            min: 4.023,
            max: 11.627
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
              const headers = {
                'Content-type': 'application/json;',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Methods': 'GET, POST'
              };
      
            //   const response = await fetch(`http://localhost:8080/api/rt/${name}`, { headers });
              const response = await fetch(`http://103.106.72.182:36013/api/rt/${name}`, { headers });
              const jsonData = await response.json();
              setData(jsonData);
            } catch (error) {
              console.log(error);
            }
        }

        fetchData();
        
    }, [name])

    const printHandler = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: 'Data Sehat',
        onAfterPrint: () => alert('Print success'),
    });

    let resultDate
    if(data != null) {
        const dataString = data.data.createdAt
        const date = new Date(dataString)
        resultDate = date.toLocaleDateString('id-ID', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
        });
    }


    const comparationResult = (tipe, value) => {
        if(tipe === 'tptl') {
            if(value < comparation.tptl.max && value > comparation.tptl.min) {
                return "normal"
            } else if(value > comparation.tptl.max) {
                return 'more'
            } else if(value < comparation.tptl.min) {
                return 'low'
            }
        } else if(tipe === 'tptb') {
            if(value < comparation.tptb.max && value > comparation.tptb.min) {
                return "normal"
            } else if(value > comparation.tptb.max) {
                return 'more'
            } else if(value < comparation.tptb.min) {
                return 'low'
            }
        } else if(tipe === 'kpt') {
            if(value < comparation.kpt.max && value > comparation.kpt.min) {
                return "normal"
            } else if(value > comparation.kpt.max) {
                return 'more'
            } else if(value < comparation.kpt.min) {
                return 'low'
            }
        } else if(tipe === 'ko') {
            if(value < comparation.ko.max && value > comparation.ko.min) {
                return "normal"
            } else if(value > comparation.ko.max) {
                return 'more'
            } else if(value < comparation.ko.min) {
                return 'low'
            }
        } else if(tipe === 'kr') {
            if(value < comparation.kr.max && value > comparation.kr.min) {
                return "normal"
            } else if(value > comparation.kr.max) {
                return 'more'
            } else if(value < comparation.kr.min) {
                return 'low'
            }
        }
    }

  return (
    <div  ref={componentRef} className='mb-7'>
      {data && (
        <div className='w-10/12 mx-auto bg-white shadow-md p-2 mt-2 '>
            <div className='w-8/12 bg-gray-100 mx-auto mt-8 p-2 pt-2 rounded'>
                <div className='flex space-x-32'>
                    <div>
                        <h5 className='m-3 font-semibold'>Nama: {data.data.name}</h5>
                        <h5 className='m-3 font-semibold'>Umur: {data.data.age}</h5>
                    </div>
                    <div>
                        <h5 className='m-3 font-semibold'>Gender: {data.data.gender}</h5>
                        <h5 className='m-3 font-semibold'>Tanggal: {resultDate}</h5>
                    </div>
                </div>
            </div>
            <div>
                
            <div class="relative overflow-x-auto shadow-md sm:rounded-lg mt-8">
                <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                    <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                        <tr>
                            <th scope="col" class="px-6 py-3">
                                Barang pengujian
                            </th>
                            <th scope="col" class="px-6 py-3">
                                Rentang Normal
                            </th>
                            <th scope="col" class="px-6 py-3">
                                Nilai Pengukuran yang sebenarnya
                            </th>
                            <th scope="col" class="px-6 py-3">
                                Hasil Pengujian
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
                            <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                Tingkat Pengapuran Tulang Leher
                            </th>
                            <td class="px-6 py-4 text-center">
                                421 - 490
                            </td>
                            <td class="px-6 py-4 text-center">
                                {data.data.tptl}
                            </td>
                            <td class="px-6 py-4">
                                <div className={`${comparationResult('tptl', data.data.tptl) == 'normal' ? 'bg-primary' : comparationResult('tptl', data.data.tptl) == 'more' ? 'bg-yellow-400' : comparationResult('tptl', data.data.tptl) == 'low' ? 'bg-red-600' : ''} w-20 h-8 rounded-sm`}></div>
                            </td>
                        </tr>
                        <tr class="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
                            <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                Tingkat Pengapuran Tulang Belakang
                            </th>
                            <td class="px-6 py-4 text-center">
                                4.326 - 7.531
                            </td>
                            <td class="px-6 py-4 text-center">
                                {data.data.tptb}
                            </td>
                            <td class="px-6 py-4">
                            <div className={`${comparationResult('tptb', data.data.tptb) == 'normal' ? 'bg-primary' : comparationResult('tptb', data.data.tptb) == 'more' ? 'bg-yellow-400' : comparationResult('tptb', data.data.tptb) == 'low' ? 'bg-red-600' : ''} w-20 h-8 rounded-sm`}></div>
                            </td>
                        </tr>
                        <tr class="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
                            <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                Koefisien Penebalan Tulang
                            </th>
                            <td class="px-6 py-4 text-center">
                                2.954 - 5.543
                            </td>
                            <td class="px-6 py-4 text-center">
                                {data.data.kpt}
                            </td>
                            <td class="px-6 py-4">
                            <div className={`${comparationResult('kpt', data.data.kpt) == 'normal' ? 'bg-primary' : comparationResult('kpt', data.data.kpt) == 'more' ? 'bg-yellow-400' : comparationResult('kpt', data.data.kpt) == 'low' ? 'bg-red-600' : ''} w-20 h-8 rounded-sm`}></div>
                            </td>
                        </tr>
                        <tr class="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
                            <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                Koefisien Osteoporosis
                            </th>
                            <td class="px-6 py-4 text-center">
                                2.019 - 4.721
                            </td>
                            <td class="px-6 py-4 text-center">
                                {data.data.ko}
                            </td>
                            <td class="px-6 py-4">
                            <div className={`${comparationResult('ko', data.data.ko) == 'normal' ? 'bg-primary' : comparationResult('ko', data.data.ko) == 'more' ? 'bg-yellow-400' : comparationResult('ko', data.data.ko) == 'low' ? 'bg-red-600' : ''} w-20 h-8 rounded-sm`}></div>
                            </td>
                        </tr>
                        <tr class="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
                            <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                Koefisien Rematik
                            </th>
                            <td class="px-6 py-4 text-center">
                                4.023 - 11.627
                            </td>
                            <td class="px-6 py-4 text-center">
                                {data.data.kr}
                            </td>
                            <td class="px-6 py-4">
                            <div className={`${comparationResult('kr', data.data.kr) == 'normal' ? 'bg-primary' : comparationResult('kr', data.data.kr) == 'more' ? 'bg-yellow-400' : comparationResult('kr', data.data.kr) == 'low' ? 'bg-red-600' : ''} w-20 h-8 rounded-sm`}></div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div className='mt-4 text-right float-right flex gap-8'>
                <div>
                    <p className='font-semibold'>Kurang</p>
                    <div  className='w-12 h-4 bg-red-600 rounded'></div>
                </div>
                <div>
                    <p className='font-semibold'>Normal</p>
                    <div  className='w-12 h-4 bg-primary rounded'></div>
                </div>
                <div>
                    <p className='font-semibold'>Abnormal</p>
                    <div  className='w-12 h-4 bg-yellow-400 rounded'></div>
                </div>

                <div></div>
            </div>

            </div>
            <button className='bg-primary hover:bg-teal-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mx-2 my-8' onClick={printHandler}>Print</button>
            <Link to='/rt' ><button className='bg-yellow-400 hover:bg-yellow-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mx-1 mt-3'>Kembali</button></Link>
        </div>
      )}
    </div>
  )
}

export default RTResult
