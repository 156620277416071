import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Link, useHistory } from 'react-router-dom';

const FormRTulang = () => {

    const [name, setName] = useState('');
    const [age, setAge] = useState('');
    const [gender, setGender] = useState('');
    const [tptl, setTptl] = useState('');
    const [tptb, setTptb] = useState('');
    const [kpt, setKpt] = useState('');
    const [ko, setKo] = useState('');
    const [kr, setKr] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault()

        const data = {
            "name" : name,
            "age" : parseInt(age),
            "gender" : gender,
            "tptl" : parseFloat(tptl),
            "tptb": parseFloat(tptb),
            "kpt": parseFloat(kpt),
            "ko": parseFloat(ko),
            "kr": parseFloat(kr)
        }

        // fetch('http://localhost:8080/api/rt', {
        fetch('http://103.106.72.182:36013/api/rt', {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-type': 'application/json;',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Methods': 'GET, POST'
            }         
        })
        .then(res => console.log(res))
        .catch(err => console.log(err))
    }

  return (
    <div>
      <h1 className='text-center font-bold text-2xl container mx-auto pt-8'>(Penyakit Rematik Tulang) Form Hasil Analisa</h1>
        <div className='bg-slate-100 shadow-md container mx-auto mt-8 p-8 w-1/2 mb-8'>
            <form className='flex' onSubmit={handleSubmit}>
                <div className='border-inherit w-96'>
                    <div className='p-3'>
                        <label htmlFor="name" className='block text-gray-700 font-bold mb-1'>Nama: </label>
                        <input
                         type='text'
                         name='name'
                         value={name}
                         id='name'
                         onChange={(e) => setName(e.target.value)} className='shadow appearance-none border rounded w-64 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'/>
                    </div>
                    <div className='p-3'>
                        <label htmlFor="gender" className="block text-gray-700 font-bold mb-1" >Gender: </label>
                        <select
                            id="gender"
                            name='gender'
                            value={gender}
                            onChange={(e) => setGender(e.target.value)}
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
                            <option value="">Pilih Jenis Kelamin</option>
                            <option value="Laki-laki">Laki-laki</option>
                            <option value="Perempuan">Perempuan</option>
                        </select>
                    </div>
                    <div className='p-3'>
                        <label htmlFor="age" className="block text-gray-700 font-bold mb-1">Umur: </label>
                        <input
                         type='number'
                         name='age'
                         value={age}
                         id='name'
                         min="0"
                         onChange={(e) => setAge(e.target.value)} className='shadow appearance-none border rounded w-64 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'/>
                    </div>

                    
                </div>

                <div>
                    <div className='p-3'>
                        <label htmlFor="tptl" className="block text-gray-700 font-bold mb-1">Tingkat Pengapuran Tulang Leher: </label>
                            <input
                            type='text'
                            name='tptl'
                            value={tptl}
                            id='tptl'
                            onChange={(e) => setTptl(e.target.value)} className='shadow appearance-none border rounded w-64 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'/>
                    </div>
                    <div className='p-3'>
                        <label htmlFor="tptb" className="block text-gray-700 font-bold mb-1">Tingkat Pengapuran Tulang Belakang: </label>
                                <input
                                type='text'
                                name='tptb'
                                value={tptb}
                                id='tptb'
                                onChange={(e) => setTptb(e.target.value)} className='shadow appearance-none border rounded w-64 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'/>
                    </div>
                    <div className='p-3'>
                        <label htmlFor="kpt" className="block text-gray-700 font-bold mb-1">Koefisien Penebalan Tulang: </label>
                                <input
                                type='text'
                                name='kpt'
                                value={kpt}
                                id='kpt'
                                onChange={(e) => setKpt(e.target.value)} className='shadow appearance-none border rounded w-64 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'/>
                    </div>
                    <div className='p-3'>
                        <label htmlFor="ko" className="block text-gray-700 font-bold mb-1">Koefisien Osteoporosis: </label>
                                    <input
                                    type='text'
                                    name='ko'
                                    value={ko}
                                    id='ko'
                                    onChange={(e) => setKo(e.target.value)} className='shadow appearance-none border rounded w-64 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'/>
                    </div>
                    <div className='p-3'>
                        <label htmlFor="kr" className="block text-gray-700 font-bold mb-1">Koefisien Rematik: </label>
                                    <input
                                    type='text'
                                    name='kr'
                                    value={kr}
                                    id='kr'
                                    onChange={(e) => setKr(e.target.value)} className='shadow appearance-none border rounded w-64 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'/>
                    </div>
                    
                    <button type="submit" className="bg-primary hover:bg-teal-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mx-1 mt-8"> Submit </button>

                    <Link to={`/rtr/${name}`} state={name}>
                    <button className='bg-lime-400 hover:bg-lime-500 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline'>Cek Hasil</button>
                    </Link>

                    <Link to='/'>
                    <button className='block mt-8 bg-yellow-400 hover:bg-yellow-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mx-1'>Kembali</button>
                    </Link>

                </div>
                
            </form>
        </div>
    </div>
  )
}

export default FormRTulang
