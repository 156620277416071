import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Link, useHistory } from 'react-router-dom';

const FormFungsiP = () => {

    const [name, setName] = useState('');
    const [age, setAge] = useState('');
    const [gender, setGender] = useState('');
    const [vc, setVc] = useState('');
    const [tlc, setTlc] = useState('');
    const [tjn, setTjn] = useState('');
    const [tpdap, setTpdap] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault()

        const data = {
            "name" : name,
            "age" : parseInt(age),
            "gender" : gender,
            "vc" : parseFloat(vc),
            "tlc": parseFloat(tlc),
            "tjn": parseFloat(tjn),
            "tpdap": parseFloat(tpdap)
        }

        // fetch('http://localhost:8080/api/fp', {
        fetch('http://103.106.72.182:36013/api/fp', {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-type': 'application/json;',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Methods': 'GET, POST'
            }         
        })
        .then(res => console.log(res))
        .catch(err => console.log(err))
    }

  return (
    <div>
      <h1 className='text-center font-bold text-2xl container mx-auto pt-8'>(Fungsi Paru) Form Hasil Analisa</h1>
        <div className='bg-slate-100 shadow-md container mx-auto mt-8 p-8 w-1/2 mb-8'>
            <form className='flex' onSubmit={handleSubmit}>
                <div className='border-inherit w-96'>
                    <div className='p-3'>
                        <label htmlFor="name" className='block text-gray-700 font-bold mb-1'>Nama: </label>
                        <input
                         type='text'
                         name='name'
                         value={name}
                         id='name'
                         onChange={(e) => setName(e.target.value)} className='shadow appearance-none border rounded w-64 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'/>
                    </div>
                    <div className='p-3'>
                        <label htmlFor="gender" className="block text-gray-700 font-bold mb-1" >Gender: </label>
                        <select
                            id="gender"
                            name='gender'
                            value={gender}
                            onChange={(e) => setGender(e.target.value)}
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
                            <option value="">Pilih Jenis Kelamin</option>
                            <option value="Laki-laki">Laki-laki</option>
                            <option value="Perempuan">Perempuan</option>
                        </select>
                    </div>
                    <div className='p-3'>
                        <label htmlFor="age" className="block text-gray-700 font-bold mb-1">Umur: </label>
                        <input
                         type='number'
                         name='age'
                         value={age}
                         id='name'
                         min="0"
                         onChange={(e) => setAge(e.target.value)} className='shadow appearance-none border rounded w-64 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'/>
                    </div>

                    
                </div>

                <div>
                    <div className='p-3'>
                        <label htmlFor="vc" className="block text-gray-700 font-bold mb-1">Kapasitas Vital VC: </label>
                            <input
                            type='text'
                            name='vc'
                            value={vc}
                            id='vc'
                            onChange={(e) => setVc(e.target.value)} className='shadow appearance-none border rounded w-64 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'/>
                    </div>
                    <div className='p-3'>
                        <label htmlFor="tlc" className="block text-gray-700 font-bold mb-1">Kapasitas Total Paru TLC: </label>
                                <input
                                type='text'
                                name='tlc'
                                value={tlc}
                                id='tlc'
                                onChange={(e) => setTlc(e.target.value)} className='shadow appearance-none border rounded w-64 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'/>
                    </div>
                    <div className='p-3'>
                        <label htmlFor="tjn" className="block text-gray-700 font-bold mb-1">Tahanan Jalan Napas RAM: </label>
                                <input
                                type='text'
                                name='tjn'
                                value={tjn}
                                id='tjn'
                                onChange={(e) => setTjn(e.target.value)} className='shadow appearance-none border rounded w-64 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'/>
                    </div>
                    <div className='p-3'>
                        <label htmlFor="tapad" className="block text-gray-700 font-bold mb-1">Tingkat Sentimen: </label>
                                    <input
                                    type='text'
                                    name='tpdap'
                                    value={tpdap}
                                    id='tpdap'
                                    onChange={(e) => setTpdap(e.target.value)} className='shadow appearance-none border rounded w-64 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'/>
                    </div>
                    
                    <button type="submit" className="bg-primary hover:bg-teal-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mx-1 mt-8"> Submit </button>

                    <Link to={`/fpr/${name}`} state={name}>
                    <button className='bg-lime-400 hover:bg-lime-500 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline'>Cek Hasil</button>
                    </Link>

                    <Link to='/'>
                    <button className='block mt-8 bg-yellow-400 hover:bg-yellow-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mx-1'>Kembali</button>
                    </Link>

                </div>
                
            </form>
        </div>
    </div>
  )
}

export default FormFungsiP
